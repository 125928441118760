import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    changePassword: {
        oldPassword: "",
        password: ""
    },
    login: {
        email: "",
        phone: "",
        password: ""
    },
    register: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        policy: false,
        token: "",
    },
    addPhone: {
        phone: "",
        country: "",
        token: "",
    },
    verification: {
        token: "",
        channel: "",
        status: false
    }
}

export const authFormSlice = createSlice({
    name: 'authForm',
    initialState,
    reducers: {
        changeverificationData: (state, action) => {
            switch (action.payload.name) {
                case "token":
                    state.verification.token = action.payload.value
                    break;
                case "channel":
                    state.verification.channel = action.payload.value
                    state.verification.status === false ? state.verification.status = true : state.verification.status = false
                    break;
                case "close":
                    state.verification = {
                        token: "",
                        channel: "",
                        status: false
                    }
                    break;
                default:
                    break;
            }
        },
        changePasswordData: (state, action) => {
            switch (action.payload.name) {

                case "password":
                    state.changePassword.password = action.payload.value
                    break;
                case "oldPassword":
                    state.changePassword.oldPassword = action.payload.value
                    break;
                default:
                    break;
            }
        },
        logInData: (state, action) => {
            switch (action.payload.name) {
                case "email":
                    state.login.email = action.payload.value
                    break;
                case "phone":
                    if (state.login.phone.length === 0 && action.payload.value === 0) {
                        break;
                    }
                    state.login.phone = action.payload.value
                    break;
                case "password":
                    state.login.password = action.payload.value
                    break;
                case "clear":
                    state.login = {
                        email: "",
                        phone: "",
                        password: ""
                    }
                    break;
                default:
                    break;
            }
        },
        registerData: (state, action) => {
            switch (action.payload.name) {
                case "firstName":
                    state.register.firstName = action.payload.value
                    break;
                case "lastName":
                    state.register.lastName = action.payload.value
                    break;
                case "email":
                    state.register.email = action.payload.value
                    break;
                case "token":
                    state.register.token = action.payload.value
                    break;
                case "password":
                    state.register.password = action.payload.value
                    break;
                case "confirm-password":
                    state.register.confirmPassword = action.payload.value
                    break;
                case "policy":
                    state.register.policy = state.register.policy === false ? true : false
                    break;
                default:
                    break;
            }
        },
        addPhoneData: (state, action) => {
            switch (action.payload.name) {
                case "phone":
                    if (state.addPhone.phone.length === 0 && action.payload.value === 0) {
                        break;
                    }
                    state.addPhone.phone = action.payload.value
                    break;
                case "country":
                    state.addPhone.country = action.payload.value
                    break;
                case "token":
                    state.addPhone.token = action.payload.value
                    break;
                default:
                    break;
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { changeverificationData, logInData, registerData, addPhoneData, changePasswordData } = authFormSlice.actions

export default authFormSlice.reducer