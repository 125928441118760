import React, { useState, useEffect } from "react";
import styles from "./transactionDetails.module.sass";
import { transactionDetails } from "../../api/wallet";
import Loader from "../Loader";

const TransactionDetails = ({ reference }) => {
  const [transaction, setTransaction] = useState({});
  const [copyText, setCopyText] = useState("copy");
  useEffect(() => {
    async function load() {
      const walletRes = await transactionDetails(reference);
      if (walletRes) {
        if (walletRes.status === true) {
          setTransaction(walletRes.data);
        }
      }
    }
    load();
  }, [setTransaction, reference])
  return (
    <div className={styles.card}>
      <p className={styles.pSum}>
        Transaction Details
      </p>
      {Object.keys(transaction).length === 0 ? <div className={styles.loader}>
        <Loader />
      </div>
        :
        <>
          <p className={transaction.amount > 0 ? styles.creditAmount : styles.debitAmount}>
            ₦ {parseInt(transaction.amount || 0).toLocaleString("en-US")}
          </p>
          <div className={styles.devider}></div>
          <p className={styles.description}>
            {transaction.description}
          </p>
          <div className={styles.summaryDetails}>
            <p className={styles.subTitle}>
              From:
            </p>
            <p >
              {`${transaction?.user?.lastName} ${transaction?.user?.firstName} `}
            </p>
          </div>
          <div className={styles.summaryDetails}>
            <p className={styles.subTitle}>
              TO:
            </p>
            <p >
              {`${transaction?.peeUser?.lastName} ${transaction?.peeUser?.firstName} `}
            </p>
          </div>

          <div className={styles.summaryDetails}>
            <p className={styles.subTitle}>
              Reference:
            </p>
            <p >
              {transaction.reference.slice(0, 27)}...<span className={styles.copy} onClick={()=>{
                navigator.clipboard.writeText(transaction.reference)
                setCopyText("copied")
              }}>{copyText}</span>
            </p>
          </div>

          <div className={styles.summaryDetails}>
            <p className={styles.subTitle}>
              Date:
            </p>
            <p >
              {transaction.created_at}

            </p>

          </div>

        </>
      }
    </div>
  );

};

export default TransactionDetails;
