import React, { useCallback, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import Dropdown from "../../../components/Dropdown";
import { sendOtp, profile } from "../../../api/profile";
import { addphoneToProfileApi } from "../../../api/profile";
import TextInput from "../../../components/TextInput";
import Message from "../../../components/Message";
import Loader from "../../../components/Loader";
import { useDispatch } from 'react-redux';
import { updateProfile, logOut } from "../../../store/auth";

const FormPhone = ({ formData, formHandler, phone, setPhone, optionsPhone }) => {
  const [visibleToken, setVisibleToken] = useState(false);
  const [visibleLoader, setVisibleLoader] = useState(false);
  const [thePhone, setThePhone] = useState("");
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const dispatch = useDispatch();

  const addPhoneToProfile = useCallback(async () => {
    const addphoneToProfileApiRes = await addphoneToProfileApi({
      phone: thePhone,
      token: formData.token
    })

    if (addphoneToProfileApiRes) {
      if (addphoneToProfileApiRes.status === true) {
        setMessage({ display: true, error: false, message: addphoneToProfileApiRes.message });
        const getUserRes = await profile({});
        if (getUserRes) {
          if (getUserRes.status === true) {
            dispatch(updateProfile(getUserRes.data));
          }
        }
        window.location.href = "/set-pin";
      } else {
        setMessage({ display: true, error: true, message: addphoneToProfileApiRes.message });
      }
    }

  }, [formData, thePhone, dispatch])

  const sendToken = useCallback(() => {
    setVisibleLoader(true)
    setTimeout(async () => {
      if (formData.phone.length < 8) {
        setMessage({ display: true, error: true, message: "Please confirm the number" });
        setVisibleLoader(false)
      } else {
        let formatedPhone = formData.phone.charAt(0);
        if (formatedPhone === "0") {
          formatedPhone = formData.phone.slice(1);
        } else {
          formatedPhone = formData.phone;
        }
        const countryCode = phone.split(" ");
        let finalPhone = `${countryCode[1]}${formatedPhone}`;
        setThePhone(finalPhone);

        const sendOtpRes = await sendOtp({
          phone: finalPhone
        });
        if (sendOtpRes) {
          if (sendOtpRes.status === true) {
            setMessage({ display: true, error: false, message: sendOtpRes.message });
            setVisibleToken(true);
            setVisibleLoader(false);
            if (sendOtpRes.message === "Account phone already verified") {
              dispatch(logOut());
            }

          } else {
            setMessage({ display: true, error: true, message: sendOtpRes.message });
            setVisibleLoader(false);
          }
        }
      }

    }, 1000);
  }, [dispatch, formData.phone, phone])
  return (
    <div className={styles.form}>
      <div className={styles.top}>
        <h3 className={cn("h3", styles.title)}>Add Phone</h3>
        <div className={styles.info}>Please provide you mobile number</div>
      </div>
      <Message display={message.display} error={message.error} message={message.message} />
      <div className={styles.container}>
        <div className={styles.container} style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <div className={styles.field}>
            <Dropdown
              className={styles.dropdown}
              label="mobile"
              value={phone}
              setValue={setPhone}
              options={optionsPhone}
            />
          </div>
          <input type="number" style={{
            width: "-webkit-fill-available",
            marginBottom: "7px",
            marginLeft: "2px",
            height: "48px",
            padding: " 0 14px",
            borderRadius: "12px",
            border: " 2px solid #E6E8EC",
            background: "#FCFCFD",
            fontFamily: "Poppins sans-serif",
            fontSize: "14px",
            lineHeight: "1.7",
            fontWeight: "500",
            color: "#23262F",
            transition: "border-color 0.2s",
          }}
            placeholder="801245678"
            name="phone"
            value={formData.phone}
            onChange={(e) => formHandler({ name: "phone", value: e.target.value })}
          />

        </div>
        {
          visibleToken ?
            <>
              <p className={styles.noToken}> Did not get token, <button onClick={() => sendToken()} className={styles.resendToken}>Resend token</ button> </p>

              <TextInput
                className={styles.field}
                label="Phone verification token"
                name="token"
                type="password"
                value={formData.token}
                changeHandler={formHandler}
                placeholder="token"
                required
                view
              />
            </>
            : <></>
        }
      </div>
      {
        visibleToken ?
          <button className={cn("button", styles.button)} onClick={() => addPhoneToProfile()}>
            {visibleLoader ? <Loader color="white" /> : "Add Phone"}
          </button> : <button onClick={() => sendToken()} className={cn("button", styles.button)} >{visibleLoader ? <Loader color="white" /> : "Get Token"}</button>
      }

    </div>
  );
};

export default FormPhone;