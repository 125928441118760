import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Form.module.sass";
import Dropdown from "../../../components/Dropdown";
import TextInput from "../../../components/TextInput";
import { signIn } from "../../../api/auth";
import { logIn } from "../../../store/auth";
import { useDispatch } from 'react-redux';
import Message from "../../../components/Message";
import Loader from "../../../components/Loader";
import {
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
const navigation = ["Email", "Mobile"];

const Form = ({ formHandler, formData, phone, setPhone, optionsPhone }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const [displayLoader, setDisplayLoad] = useState(false);
  const [reCapToken, setreCapToken] = useState();
  const dispatch = useDispatch();

  const captchaChange = useCallback((value) => {
    setreCapToken(value)
  }, []);


  return (
    <div className={styles.form}>
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => {
              formHandler({ name: "clear", value: "" })
              setActiveIndex(index)
            }}
            key={index}
            type="button"
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.container}>

        <Message display={message.display} error={message.error} message={message.message} />
        {activeIndex === 0 && (
          <TextInput
            className={styles.field}
            label="email"
            name="email"
            type="email"
            value={formData.email}
            changeHandler={formHandler}
            placeholder="Email address"
            required
          />
        )}
        {activeIndex === 1 && (
          <div className={styles.line}>
            <div className={styles.field}>
              <Dropdown
                className={styles.dropdown}
                label="mobile"
                value={phone}
                setValue={setPhone}
                options={optionsPhone}
              />
            </div>
            <TextInput
              className={styles.field}
              name="phone"
              value={formData.phone}
              changeHandler={formHandler}
              type="tel"
              required
            />
          </div>
        )}
      </div>

      <TextInput
        className={styles.field}
        label="Password"
        name="password"
        type="password"
        value={formData.password}
        changeHandler={formHandler}
        placeholder="Password"
        required
        view
      />
      <div className={styles.foot}>

        <Link className={styles.link} to="/forgot-password">
          Forgot password?
        </Link>
      </div>
      <GoogleReCaptcha onVerify={captchaChange} />
      <button onClick={async () => {
        setDisplayLoad(true)
        const email = formData.email;
        const password = formData.password;
        let formartedPhone = "";

        if (parseInt(formData.phone.charAt(0)) > 0) {
          formartedPhone = `234${phone}`;
        } else {
          formartedPhone = formData.phone.replace(/^0+/, "234");
        }

        const payload = {
          emailPhone: email ? email : formartedPhone,
          password,
          reCapToken
        }
 
        const loginRes = await signIn(payload)

        if (loginRes) {
          if (loginRes.status === true) {
            setMessage({ display: true, error: false, message: loginRes.message });
            dispatch(logIn(loginRes.data));
            setDisplayLoad(false);
            formHandler({ name: "password", value: "" });
            window.location.replace("/dashboard");
          } else {
            setMessage({ display: true, error: true, message: loginRes.message });
            formHandler({ name: "password", value: "" });
            setDisplayLoad(false);
          }
        } else{
          setDisplayLoad(false);
        }
      }} className={cn("button", styles.button)}>{displayLoader ? <Loader color="white" /> : "Login"}</button>
    </div>
  );
};

export default Form;
