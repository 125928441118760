import React, { useState, useEffect } from "react";
import styles from "./Users.module.sass";
import AdminLayout from "../../../components/AdminLayout";
import AdminUsers from "../../../components/AdminUsers";
import { allUsers } from "../../../api/admin/users";
import { Bars } from 'react-loader-spinner'

const Users = () => {
  const [allUser, setAllUser] = useState([]);
 
  useEffect(() => {

    async function load(){
      const allUsersRes = await allUsers({page:0, size: 100 });
      
      if (allUsersRes) {
        if (allUsersRes.status === true) {
          setAllUser(allUsersRes.data)
        }
      }
    }
    load();

  }, []);

  if (allUser.length === 0) {
    return <>
      <AdminLayout>
        <div className={styles.top}>
          <Bars
            height="80"
            width="180"
            color="#3772FF"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />

        </div>

      </AdminLayout>
    </>
  }
  return (
    <>
      <AdminLayout>
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.head}>All Users</div>
            <div className={styles.body}>
              <AdminUsers allUser={allUser} />
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default Users;
