import React, { useState, useEffect, useCallback } from "react";
import cn from "classnames";
import styles from "./WalletOverview.module.sass";
import Wallet from "../../components/Wallet";
import AccountBalances from "./AccountBalances";
import { useDispatch, useSelector } from 'react-redux';
import Card from "../Investments/Card";
import Modal from "../../components/Modal";
import Investment from "../../components/Investment";
import { getInvestment } from "../../api/investment";
import { profile } from "../../api/profile";
import { getBils } from "../../api/bills";
import { updateProfile } from "../../store/auth";
import { updateBills } from "../../store/bills";
import Withdraw from "../../components/Withdraw";
import DepositModel from "../../components/DepositModel";
import P2p from "../../components/P2p";
import Airtime from "../../components/Airtime";
import Data from "../../components/Data";
import Bills from "../../components/Bills";

const WalletOverview = () => {
  const userDetails = useSelector((state) => state.auth);
  const billsData = useSelector((state) => state.bills);
  const [visibleInvestment, setVisibleInvestment] = useState({ visible: false, selected_id: "" });
  const [rawData, setRawData] = useState([]);
  const [investmentData, setinvestmentData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [copy, setCopy] = useState("copy");
  const [visibleTransfer, setVisibleTransfer] = useState(false);
  const [visibleP2p, setVisibleP2p] = useState({ status: false, wallet: "" });
  const [visibleDeposit, setVisibleDeposit] = useState(false);
  const [visibleAirtime, setVisibleAirtime] = useState(false);
  const [visibleData, setVisibleData] = useState(false);
  const [visibleBills, setVisibleBills] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function load() {
      const getProfile = await profile();
      if (getProfile) {
        if (getProfile.status) {
          dispatch(updateProfile(getProfile.data));
        }
      }

      const loadInvestment = await getInvestment({ page: 0, size: 100 });
      const sortedData = [];
      if (loadInvestment) {
        if (loadInvestment.data) {
          if (loadInvestment.data.investment) {
            setRawData(loadInvestment.data.investment);
            for (let index = 0; index < loadInvestment.data.investment.length; index++) {
              const invest = loadInvestment.data.investment[index];

              sortedData.push({
                uuid: invest.uuid,
                title: invest.title,
                amount: invest.amount,
                return: invest.return,
                button: "View Details",
                image: invest.image[0],
                image2x: invest.image[1]
              })
            }
            setinvestmentData(sortedData);
          }
        }
      }

      if (userDetails.user) {
        if (Object.keys(userDetails.user).length > 0) {
          if (!userDetails.user.phone_verified_at) {
            window.location.href = "/add-phone";
          } else if (!userDetails.user.isPin) {
            window.location.href = "/set-pin";
          }
        }
      }
      const loadBills = await getBils();

      if (loadBills) {
        dispatch(updateBills(loadBills.data));
      }
    }


    load();
  }, []);

  const sumBalance = useCallback(() => {
    let sumBalance = 0;
    if (userDetails.wallets.length > 0) {
      userDetails.wallets.forEach(wb => {
        sumBalance = sumBalance + parseInt(wb.amount);
      });
    }
    return sumBalance;
  }, [userDetails.wallets])

  const handleSelect = useCallback(({ visible, selected_id }) => {
    if (rawData) {
      for (let index = 0; index < rawData.length; index++) {
        if (rawData[index].uuid === selected_id) {
          setSelectedData(rawData[index]);
        }

      }
    }
    setVisibleInvestment({ selected_id, visible: visible })
  }, [rawData])


  return (
    <Wallet>
      <div className={styles.top}>
        <div className={styles.line}>
          <h4 className={cn("h4", styles.title)}>Wallets</h4>
        </div>
        <div className={[styles.details, styles.flexHeader]}>
          {userDetails.virtualAccounts?.number ?
            <div className={styles.info}>{userDetails.virtualAccounts.number} {userDetails.virtualAccounts.bank} <button onClick={() => { navigator.clipboard.writeText(userDetails.virtualAccounts.number); setCopy("copied") }}>{copy}</button></div>
            : ""}
          <div className={styles.info}>Total balance</div>
          <div className={styles.currency}>
            <div className={styles.number}>₦ {parseInt(sumBalance()).toLocaleString("en-US")}</div>
            <div className={cn("category-green", styles.category)}>NGN</div>
          </div>
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.head}>Account Balances</div>
        <div className={styles.body}>
          <AccountBalances setVisibleP2p={setVisibleP2p} setVisibleTransfer={setVisibleTransfer} visibleDeposit={visibleDeposit} setVisibleDeposit={setVisibleDeposit} isBanks={userDetails.user?.isBanks} wallets={userDetails.wallets} />
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.head}>Airtime &  Bills payments</div>
        <div className={styles.body}>
          <div className={styles.bilssSections}>
            <button
              className={styles.airtime}
              onClick={() => setVisibleAirtime(true)}
            >
              <img
                className=""
                src="/images/content/icons8-phone-50.png"
                alt="RisenSail"
              />
              <span>Airtime</span>
            </button>
            <button
              className={styles.data}
              onClick={() => setVisibleData(true)}
            >
              <img
                className=""
                src="/images/content/icons8-global-50.png"
                alt="RisenSail"
              />
              <span>Data</span>
            </button>
            <button
              className={styles.bills}
              onClick={() => setVisibleBills(true)}
            >
              <img
                className=""
                src="/images/content/bill.png"
                alt="RisenSail"
              />
              <span>Bills</span>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.list}>
        {investmentData.map((x, index) => (
          <Card className={styles.card} item={x} key={index} handleSelect={handleSelect} />
        ))}
        <Modal
          visible={visibleInvestment.visible}
          onClose={() => setVisibleInvestment({ ...visibleInvestment, visible: false })}
        >
          <Investment data={selectedData} />
        </Modal>
      </div>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <Withdraw />
      </Modal>
      <Modal
        visible={visibleP2p.status}
        onClose={() => setVisibleP2p({ status: false, wallet: "" })}
      >
        <P2p wallet={userDetails?.wallets[0]} />
      </Modal>
      <Modal
        visible={visibleDeposit}
        onClose={() => setVisibleDeposit(false)}
      >
        <DepositModel />
      </Modal>
      <Modal
        visible={visibleAirtime}
        onClose={() => setVisibleAirtime(false)}
      >
        <Airtime data={billsData?.bills?.airtime_data} />
      </Modal>
      <Modal
        visible={visibleData}
        onClose={() => setVisibleData(false)}
      >
        <Data data={billsData?.bills?.airtime_data} />
      </Modal>
      <Modal
        visible={visibleBills}
        onClose={() => setVisibleBills(false)}
      >
        <Bills data={billsData?.bills?.bills} />
      </Modal>
    </Wallet>
  );
};

export default WalletOverview;
