import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    wallets: [],
    assets: [],
}

export const walletSlice = createSlice({
    name: 'wallets',
    initialState,
    reducers: {
        addWallet: (state, action) => {
            state.wallets = action.payload;
        },
        addAsset: (state, action) => {
            state.assets = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { addWallet, addAsset } = walletSlice.actions

export default walletSlice.reducer