import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import Theme from "../../Theme";
import { logOut } from "../../../store/auth";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const items = [
  {
    title: "Dashboard",
    icon: "laptop",
    content: "Your personalize dashboard",
    url: "/dashboard",
  },
  {
    title: "Investments",
    icon: "cloud",
    content: "Invest in your tomorrow",
    url: "/investments",
  },
  {
    title: "My Investments",
    icon: "cloud",
    content: "Invest in your tomorrow",
    url: "/my-investments",
  },
  {
    title: "Bills",
    icon: "sell",
    content: "Puy for you utility bills",
    url: "/bills",
  },
  {
    title: "Transactions",
    icon: "sell",
    content: "View your transactions",
    url: "/transactions",
  },
  {
    title: "Profile & Settings",
    icon: "user",
    content: "Important account details",
    url: "/profile-info",
  },
  {
    title: "Dark mode",
    icon: "theme-dark",
    content: "Switch dark/light mode",
  },
  {
    title: "Log out",
    icon: "exit",
    url: "/",
  },
];

const User = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const userDetails = useSelector((state) => state.auth.user);
  const dispatch = useDispatch()
  
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(className, styles.user, { [styles.active]: visible })}>
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img src={userDetails?.avatar ? userDetails.avatar[0] : "/images/content/avatar-user.jpg"} alt="Avatar" />
        </button>
        <div className={styles.body}>
          <div className={styles.menu}>
            {items.map((x, index) =>
              x.url ? x.icon === "exit" ? (
                <Link
                  className={styles.item}
                  to={x.url}
                  onClick={() => {
                    dispatch(logOut())
                    localStorage.removeItem('auth');
                    setVisible(!visible)
                  }}
                  key={index}
                >
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.title}>{x.title}</div>
                    <div className={styles.content}>{x.content}</div>
                  </div>
                </Link>
              ) : (
                <Link
                  className={styles.item}
                  to={x.url}
                  onClick={() => setVisible(!visible)}
                  key={index}
                >
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.title}>{x.title}</div>
                    <div className={styles.content}>{x.content}</div>
                  </div>
                </Link>
              ) : (
                <div className={styles.item} key={index}>
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="20" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.line}>
                      <div className={styles.title}>{x.title}</div>
                      <Theme className={styles.theme} small />
                    </div>
                    <div className={styles.content}>{x.content}</div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
