import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = () => {

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h1", styles.title)}>About us</h1>
        </div>
      </div>
      <div className={cn("container")}>
        <p className={styles.text}>
        <b>Risensail</b> is a startup investment broker that aims to provide easy, secure, and affordable access to top and vetted investment opportunities in Africa. The platform is based in Nigeria and is led by IT professionals and entrepreneurs. The stock market in Africa is rapidly growing, but there is a lack of reliable and user-friendly platforms. Risensail's mission is to bridge this gap by providing a robust and secure platform that offers seamless trading and investment experiences for both novice and experienced traders. Risensail will support various stock exchanges and trading pairs, comply with KYC/AML regulations, and implement strict security measures to ensure the safety of customers' funds. We offer customer service and support to provide a seamless user experience. We innovate investment opportunities in the agricultural, real estate, and pharmaceutical sectors, where individuals can invest in the production and export of farm produce, housing, etc. Risensail bridges the gap between the stock market, real estate, and the agricultural sector, enabling investors to participate in the development and growth of this crucial industry. This approach not only fosters economic growth but also supports sustainable agricultural practices and enhances food security and housing in the region.
        </p>

        <h1 className={cn("h1", styles.title)}>Mission and Vision</h1>
        <p className={styles.text}><b>Mission:</b> Our mission is to provide easy, secure, and affordable access to top and vetted investment opportunities in African, bridging the gap in the stock market and empowering individuals to participate in economic growth and development.</p>
        <p><br /></p>
        <p className={styles.text}><b>Vision:</b> Our vision is to become the leading investment broker in Africa, recognized for our secure, robust platform, exceptional
          customer service, and innovative approach to diversifying investment opportunities.</p>
        <p><br /></p>
        <p><b> Core Values</b></p>
        <p></p>
        <p className={styles.text}><b>Integrity:</b> We prioritize honesty, transparency, and ethical practices in all our interactions with customers, partners, and stakeholders.</p>
        <p className={styles.text}><b>Innovation:</b> We constantly strive to innovate and improve our platform and services to meet the evolving needs of our customers and the market.</p>
        <p className={styles.text}><b>Customer Focus:</b> We put our customers at the center of everything we do, ensuring their satisfaction and success.</p>
        <p className={styles.text}><b>Collaboration:</b> We believe in the power of collaboration and actively seek partnerships and alliances that enhance our ability to serve our customers and achieve our goals.</p>
        <p className={styles.text}><b>Social Impact:</b> We are committed to making a positive social impact by supporting sustainable agricultural practices, enhancing food security, and promoting affordable housing in African.</p>
        <p><br /></p>
        <p><b>Our Team</b></p>
        <p className={styles.text}>At Risensail, we have assembled a team of experienced professionals with a deep understanding of the investment industry
          and a passion for driving economic growth in Africa. Our team combines expertise in IT, finance,
          marketing, and customer service to deliver a seamless and secure experience for our customers. We are dedicated to
          providing exceptional service and support to ensure our customers&apos; success in their investment journey.</p>
      </div>

    </div>
  );
};

export default Main;
