import React, { useState, useCallback } from "react";
import cn from "classnames";
import styles from "./AdminTransaction.module.sass";
import TransactionDetails from "../TransactionDetails";
import Modal from "../Modal";
const AdminTransaction = ({ alltransaction }) => {
  const [visible, setVisible] = useState(false);
  const [referenceDetail, setReferenceDetail] = useState("");
  const handleSelect = useCallback((reference) => {
    setReferenceDetail(reference)
    setVisible(true)
  }, [setReferenceDetail, setVisible])

  if (!alltransaction) {
    return <></>
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>From/ To</div>
          <div className={styles.col}>Amount/ Balance</div>
          <div className={styles.col}>Reference/ Description</div>
          <div className={styles.col}>Status/ Type </div>
          <div className={styles.col}> Provider/ provider ref</div>
          <div className={styles.col}> Date</div>
          <div className={styles.col}> Actions</div>
        </div>
        {alltransaction?.transactions.length > 0 ? alltransaction?.transactions.map(({ id, amount, balance, description, peeruser, provider, provider_reference, type, reference, status, user, created_at }) => (

          <div
            onClick={() => handleSelect(reference)}
            className={styles.row}
            key={id}
          >
            <div className={styles.col}>
              <div className={styles.currency}>
                <div className={styles.icon}>
                  <div className={cn(amount < 0 ? "category-red" : "category-green", styles.category)}>{amount < 0 ? "⇽" : "⇾"}</div>
                </div>
                <div className={styles.details}>
                  <div className={styles.info}> {user.firstName} {user.lastName}</div>
                  <div className={styles.text}>{peeruser.firstName} {peeruser.lastName}</div>
                </div>
              </div>
            </div>

            <div className={styles.col}>
              <div className={styles.info}> ₦ {parseInt(amount).toLocaleString("en-US")}</div>
              <div className={styles.text}>₦ {parseInt(balance).toLocaleString("en-US")}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.info}> {reference}</div>
              <div className={styles.text}>{description}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.info}><span className={status==="successful"? styles.statusGreen: status==="pending"? styles.statusYellow: styles.statusRed}> {status}</span> </div>
              <div className={styles.text}>{type}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.info}> {provider}</div>
              <div className={styles.text}>{provider_reference}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.text}>{created_at}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.colAct}>
                <button
                  className={cn("button-stroke button-small", styles.buttonRedLight)}
                >
                  <span>Flag</span>
                </button>
                <button
                  className={cn("button-stroke button-small", styles.buttonYellow)}
                >
                  <span>Retry</span>
                </button>
              </div>
              <div className={styles.colAct}>
             
                <button
                  className={cn("button-stroke button-small", styles.button)}
                >
                  <span>UnFlag</span>
                </button>
                <button
                  className={cn("button-stroke button-small", styles.buttonRed)}
                >
                  <span>Reversal</span>
                </button>
              </div>
            </div>

          </div>
        )) : <>
          <div style={{
            marginLeft: "10%"
          }}>No users </div>
        </>}
      </div>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <TransactionDetails reference={referenceDetail} />
      </Modal>
    </div>
  );
};

export default AdminTransaction;
