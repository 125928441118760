import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    bills: []
}

export const billsSlice = createSlice({
    name: 'bills',
    initialState,
    reducers: {
        updateBills: (state, action) => {
            state.bills = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateBills } = billsSlice.actions

export default billsSlice.reducer