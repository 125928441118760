import axios from "axios";
import cogoToast from 'cogo-toast';
// const baseURL = "http://localhost:8080"; 
const baseURL = "https://api.risensail.com";
let authToken = '';


const token = localStorage.getItem('token');
if (token) {
    authToken = `Beare ${token}`;
}


let axiosIstans = axios.create({
    baseURL,
    headers: {
        Authorization: authToken,
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});

export const post = async ({ url, payload }) => {
    try {
        //try to login to the server 
        const requestRes = await axiosIstans.post(url, payload);

        // get the server response 
        if (requestRes.status >= 200 && requestRes.status < 400) {

            if (requestRes.data.status === true) {
                return requestRes.data
            } else {
                cogoToast.warn(requestRes.data.message).then(() => null);

                return requestRes.data
            }
        }
    } catch (error) {
        if (error.response) {
            if (error.response.status >= 400 && error.response.status < 500) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    cogoToast.error(error.response.data.message).then(() => window.location.replace('/sign-in'))
                    if (error.response.data) {
                        return error.response.data;
                    }
                } else
                    if (error.response.data) {
                        return error.response.data;
                    }
            } else {
                cogoToast.warn('Please try again shortly').then(() => null);
            }
        } else {
            cogoToast.warn('Please try again shortly').then(() => null);
        }
    }
}

export const get = async ({ url }) => {
    try {
        //try to login to the server 
        const requestRes = await axiosIstans.get(url);
        // get the server response 
        if (requestRes.status >= 200 && requestRes.status < 400) {
            if (requestRes.data.status === true) {
                return requestRes.data
            } else {
                return requestRes.data
            }
        }
        // save the response with 
    } catch (error) {
        if (error.response) {
            if (error.response.status >= 400 && error.response.status < 500) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    cogoToast.error(error.response.data.message).then(() =>
                        window.location.replace('/'));
                    if (error.response.data) {
                        return error.response.data;
                    }
                } else
                    if (error.response.data) {
                        return error.response.data
                    }
            } else {
                cogoToast.warn('Please try again shortly').then(() => null);
            }
        } else {
            cogoToast.warn('Please try again shortly').then(() => null);
        }
    }
}
