import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: null,
    user: {},
    wallets: [],
    virtualAccounts: null,
    refreshToken: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logIn: (state, action) => {
            state.user = action.payload.profile;
            state.token = action.payload.token;
            state.wallets = action.payload.wallets;
            state.virtualAccounts = action.payload.virtualAccounts;
            localStorage.setItem('profile', JSON.stringify(state));
            localStorage.setItem('token', action.payload.token);
        },
        logOut: (state) => {
            state.user = {};
            state.token = null;
            localStorage.removeItem('token');
            localStorage.removeItem('profile');
            window.location.replace("/");
        },
        updateProfile: (state, action) => {
            state.user = action.payload.profile;
            state.wallets = action.payload.wallets;
            state.virtualAccounts = action.payload.virtualAccounts;
            localStorage.setItem('profile', JSON.stringify(state));
        },
        loadAuth: (state, action) => {
            state.token = action.payload.token;
            state.user = action.payload.profile;
            state.wallets = action.payload.wallets;
            state.virtualAccounts = action.payload.virtualAccounts;
        },
      
        anable2fa: (state) => {
            let currrentStatus = state.user.twofa === true ? false : true
            state.user = { ...state.user, twofa: currrentStatus };

            const getLocalAuthdetails = localStorage.getItem('auth');
            if (getLocalAuthdetails) {
                const authDara = JSON.parse(getLocalAuthdetails);
                localStorage.setItem('auth', JSON.stringify({ ...authDara, user: { ...authDara.user, twofa: currrentStatus } }));
            }

        },
        updateAvatar: (state, action) => {
            state.user = { ...state.user, avatar: action.payload };
            const getLocalAuthdetails = localStorage.getItem('auth');
            if (getLocalAuthdetails.status) {
                const authDara = JSON.parse(getLocalAuthdetails);
                localStorage.setItem('auth', JSON.stringify({ ...authDara, user: { ...authDara.user, avatar: action.payload } }));
            }

        },
    },
})

// Action creators are generated for each case reducer function
export const { logIn, logOut, loadAuth, updateBalance, anable2fa, updateProfile, updateAvatar } = authSlice.actions

export default authSlice.reducer