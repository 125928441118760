import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink } from "react-router-dom";
import Image from "../Image";
import Dropdown from "./Dropdown";
import User from "./User";
import { useSelector } from 'react-redux';

const authNave = [
  {
    title: "Invest",
    url: "/investments",
  },
  {
    title: "FAQ",
    url: "/faq",
  }
]
const navigation = [
  {
    title: "FAQ",
    url: "/faq",
  }
];

const Header = ({ headerWide, showMenue, dashboard }) => {
  const [visibleNav, setVisibleNav] = useState(false);
  const authData = useSelector((state) => state.auth);
  return (
    <header className={cn(styles.header, { [styles.wide]: headerWide })}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logo}
          to={!dashboard ? "/" : "/dashboard"}
          onClick={() => setVisibleNav(false)}
        >
          <Image
            className=""
            src="/images/Vector.png"
            srcDark="/images/Vector_dack_mode.png"
            alt="RisenSail"
          />
        </Link>
        <div className={styles.wrapper}>
          <div className={cn(styles.wrap, { [styles.visible]: visibleNav })}>
            <nav className={styles.nav}>
              {!authData.token ? navigation.map((x, index) =>
                x.dropdown ? (
                  <Dropdown
                    className={styles.dropdown}
                    key={index}
                    item={x}
                    setValue={setVisibleNav}
                  />
                ) : (
                  <NavLink
                    className={styles.item}
                    activeClassName={styles.active}
                    onClick={() => setVisibleNav(false)}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                )
              ) : showMenue ? authNave.map((x, index) =>
                x.dropdown ? (
                  <Dropdown
                    className={styles.dropdown}
                    key={index}
                    item={x}
                    setValue={setVisibleNav}
                  />
                ) : (
                  <NavLink
                    className={styles.item}
                    activeClassName={styles.active}
                    onClick={() => setVisibleNav(false)}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                )
              ) : null}
            </nav>

            {!authData.token ? <div className={styles.btns}>
              <Link
                className={cn("button-small", styles.button)}
                activeClassName={styles.active}
                to="/sign-up"
              >
                Sign Up
              </Link>
              <Link
                className={cn("button-stroke button-small", styles.button)}
                activeClassName={styles.active}
                to="/sign-in"
              >
                Login
              </Link>
            </div> : <>

            </>}
          </div>

          {!authData.token ? <>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              activeClassName={styles.active}
              to="/sign-in"
              onClick={() => setVisibleNav(false)}
            >
              Login
            </Link>
          </> : <>
            <div className={styles.control}>
              {!dashboard ?
                <NavLink
                  className={cn("button-stroke button-small", styles.button)}
                  activeClassName={styles.active}
                  to="/dashboard"
                >
                  Dashboard
                </NavLink> : null}
              <span className={styles.userName}><b>{authData?.user?.firstName ? authData?.user?.firstName : ""}</b></span>
              {authData?.user?.firstName ? <User className={styles.user} />:""}
            </div>
          </>
          }

        </div>
      </div>
    </header>
  );
};

export default Header;
