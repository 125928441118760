import React, { useState, useCallback } from "react";
import Profile from "../../components/Profile";
import Form from "./Form";
import Finish from "./Finish";
import { useSelector, useDispatch } from 'react-redux';
import { changePasswordData } from "../../store/authForm";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Change password",
  },
];

const ChangePassword = () => {
  const formData = useSelector((state) => state.authForm.changePassword);
  const [finish, setFinish] = useState(false);
  const dispatch = useDispatch();

  const formHandler = useCallback((value) => {
    dispatch(changePasswordData(value));
  }, [dispatch]);

  return (
    <Profile title="Change password" breadcrumbs={breadcrumbs}>
      {finish ? <Finish /> : <Form formData={formData} changeHandler={formHandler} goFinish={() => setFinish(true)} />}
    </Profile>
  );
};

export default ChangePassword;
