import React, { useState, useCallback, useEffect } from "react";
import Login from "../../components/Login";
import Form from "./Form";
import Confirm from "./Confirm";
import { useSelector, useDispatch } from 'react-redux';
import { registerData } from "../../store/authForm"
const SignUp = ({history}) => {
  console.log(history.location.search);
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [referral, setReferral] = useState("");
  const reformData = useSelector((state) => state.authForm.register);
  const dispatch = useDispatch();
  useEffect(()=>{
    let theref = history.location?.search;
    if (theref) {
      theref = theref.split("=");
      setReferral(theref[1]);
    }
  },[history.location])
  const reFormHandler = useCallback((value) => {
    dispatch(registerData(value));
  }, [dispatch]);

  return (
    <Login
      content="Already have an account?"
      linkText="Login"
      linkUrl="/sign-in"
    >
      {activeIndex === 0 && <Form referral={referral} formHandler={reFormHandler} formData={reformData} goNext={(e) => setActiveIndex(e)} />}
      {activeIndex === 1 && <Confirm referral={referral} goNext={(e) => setActiveIndex(e)} formHandler={reFormHandler} formData={reformData} />}
    </Login>
  );
};

export default SignUp;
