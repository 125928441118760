import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.sass";
import AdminLayout from "../../../components/AdminLayout";
import { allUsers } from "../../../api/admin/users";
import { Bars } from 'react-loader-spinner'
import AdminUsers from "../../../components/AdminUsers";

const Dashboard = () => {
  const [allUser, setAllUser] = useState([]);

  useEffect(() => {

    async function load() {
      const allUsersRes = await allUsers({ page: 0, size: 100 });

      if (allUsersRes) {
        if (allUsersRes.status === true) {
          setAllUser(allUsersRes.data)
        }
      }
    }
    load();

  }, []);

  if (allUser.length === 0) {
    return <>
      <AdminLayout>
        <div className={styles.top}>
          <Bars
            height="80"
            width="180"
            color="#3772FF"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />

        </div>

      </AdminLayout>
    </>
  }
  return (
    <>
      <AdminLayout>

        <div className={styles.item}>
          <div className={styles.head}>Account Balances</div>
          <div className={styles.list}>
            <div class={styles.cssyq4haj}>
              <div class={styles.csshylz56}>
                <div class={styles.css1u5jwep}>
                  <img src="./users.png" alt="users"/>
                </div>
                <div class={styles.css1dd0jam}>
                  <dl>
                    <dt class={styles.css12rqbe4}>Users</dt>
                    <dd class={styles.cssbsqw91}>4m</dd>
                  </dl>
                </div>
                <div class="css-1a6fgrb">
                </div>
              </div>
            </div>

            <div class={styles.cssyq4haj}>
              <div class={styles.csshylz56}>
                <div class={styles.css1u5jwep}>
                  <img src="./users.png" alt="users"/>
                </div>
                <div class={styles.css1dd0jam}>
                  <dl>
                    <dt class={styles.css12rqbe4}>New Users</dt>
                    <dd class={styles.cssbsqw91}>250k</dd>
                  </dl>
                </div>
                <div class="css-1a6fgrb">
                </div>
              </div>
            </div>
            <div class={styles.cssyq4haj}>
              <div class={styles.csshylz56}>
                <div class={styles.css1u5jwep}>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" className={styles.css7zspnv} height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path></svg>
                </div>
                <div class={styles.css1dd0jam}>
                  <dl>
                    <dt class={styles.css12rqbe4}>Inflow</dt>
                    <dd class={styles.cssbsqw91}>₦4.2b</dd>
                  </dl>
                </div>
                <div class="css-1a6fgrb">
                </div>
              </div>
            </div>

            <div class={styles.cssyq4haj}>
              <div class={styles.csshylz56}>
                <div class={styles.css1u5jwep}>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" className={styles.css7zspnv} height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path></svg>
                </div>
                <div class={styles.css1dd0jam}>
                  <dl>
                    <dt class={styles.css12rqbe4}>Outflow</dt>
                    <dd class={styles.cssbsqw91}>₦675.4m</dd>
                  </dl>
                </div>
                <div class="css-1a6fgrb">
                </div>
              </div>
            </div>

            <div class={styles.cssyq4haj}>
              <div class={styles.csshylz56}>
                <div class={styles.css1u5jwep}>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" className={styles.css7zspnv} height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path></svg>
                </div>
                <div class={styles.css1dd0jam}>
                  <dl>
                    <dt class={styles.css12rqbe4}>Investment</dt>
                    <dd class={styles.cssbsqw91}>₦350.4b</dd>
                  </dl>
                </div>
                <div class="css-1a6fgrb">
                </div>
              </div>
            </div>

            <div class={styles.cssyq4haj}>
              <div class={styles.csshylz56}>
                <div class={styles.css1u5jwep}>
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" className={styles.css7zspnv} height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path></svg>
                </div>
                <div class={styles.css1dd0jam}>
                  <dl>
                    <dt class={styles.css12rqbe4}>Bills</dt>
                    <dd class={styles.cssbsqw91}>₦350.4m</dd>
                  </dl>
                </div>
                <div class="css-1a6fgrb">
                </div>
              </div>
            </div>

          </div>

        </div>

        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.head}>All Users</div>
            <div className={styles.body}>
              <AdminUsers allUser={allUser} />
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default Dashboard;
