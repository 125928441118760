import React from "react";
import Profile from "../../components/Profile";
import AuthorizationPin from "./AuthorizationPin";
import { useSelector } from 'react-redux';

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Authorization Pin",
  },
];

const AuthorizationPinMain = () => {
  const authData = useSelector((state) => state.auth);

  return (
    <Profile title="Authorization Pin" breadcrumbs={breadcrumbs}>
      <AuthorizationPin isPin={authData.user.isPin} />
    </Profile>
  );
};

export default AuthorizationPinMain;
