import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";

const items = [
  {
    title: "Verify your identity",
    image2x: "/images/content/lock@2x.png",
    image: "/images/content/lock.png",
    content:
      "Complete the identity verification process to secure your account and transactions.",
  },
  {
    title: "Funds your account",
    image2x: "/images/content/cards.png",
    image: "/images/content/cards.png",
    content:
      "Add funds to Your account, using any of our secure payment methods",
  },
  {
    title: "Start investing",
    image2x: "/images/content/education-pic-4.png",
    image: "/images/content/education-pic-4.png",
    content:
      "Invest on our secure platform and earn up to 30% return on investment",
  },
  {
    title: "explore opportunities",
    image2x: "/images/content/step-4.png",
    image: "/images/content/step-4.png",
    content:
      "Explore a world of investment opportunities,like stocks, bonds, venture capital etc",
  }
];

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>How it works</h2>
          <div className={styles.info}>
            RisenSail removes the complexity of investing in the stock market, through the following easy steps.
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.preview}>
                <img
                  srcSet={`${x.image2x} 2x`}
                  src={x.image}
                  alt={`Step ${index + 1}`}
                />
              </div>
              <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
