import {post, get} from "../config/axios"

export const listWallet = async ()=>await get({url:"/wallet/"})

export const walletTransactions = async ()=>await get({url:`/wallet/transactions`})

export const transactionDetails = async (reference)=>await get({url:`/wallet/transactions/${reference}`})

export const resolveAddress = async (payload)=>await post({url:"/wallet/resolve-address", payload})

export const sendP2P = async (payload)=>await post({url:"/wallet/send", payload})

export const fundAccount = async (payload)=>await post({url:"/wallet/fund-account", payload})

export const withdrawal = async (payload)=>await post({url:"/wallet/withdrawal", payload})