import React from "react";
import cn from "classnames";
import styles from "./AccountBalances.module.sass";
import { Link } from "react-router-dom";


const AccountBalances = ({ isBanks = null, wallets, setVisibleDeposit, visibleDeposit, setVisibleTransfer, setVisibleP2p }) => {

  return (
    <>
      <div className={styles.list}>
        {wallets.map((x, index) => (

          <div
            key={`wallet_${index}`}
            className={cn(x.title === "Available" ? styles.item : styles.item2, { [styles.soon]: x.soon })}
          >
            {x.title === "Available" ?
              <Link
                key={`wallet_${index}`}
                className={styles.head}
                to={`/transactions`}
              >
                <div className={styles.title}>
                  {x.title}
                </div>
                <div className={styles.details}>
                  <div className={styles.currency}> ₦{parseInt(x.amount).toLocaleString("en-US")}</div>
                </div>
              </Link> : <Link
                key={`wallet_${index}`}
                className={styles.head}
                to={x.title === "Investment" ? `/investments` : '#'}
              >
                <div className={styles.title}>
                  <div
                    className={styles.bg}
                    style={{ backgroundColor: x.color }}
                  ></div>
                  {x.title}
                </div>
                <div className={styles.details}>
                  <div className={styles.currency}>  ₦{parseInt(x.amount).toLocaleString("en-US")}</div>
                </div>
              </Link>
            }

            <div className={styles.body}>
              {x.soon ? (
                <div className={styles.soon}>Coming soon</div>
              ) : x.title === "Available" ? (
                <div className={styles.btns}>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={() => setVisibleDeposit(!visibleDeposit)}
                  >
                    <span>Deposit</span>
                  </button>
                  {!isBanks ?
                    <Link
                      className={cn("button-stroke button-small", styles.button)}
                      to={`/banks`}
                    >
                      <span>Add Banks</span>
                    </Link>
                    :
                    <button
                      className={cn("button-stroke button-small", styles.button)}
                      onClick={() => setVisibleTransfer(true)}
                    >
                      Withdraw
                    </button>
                  }

                  {/* <Link
                    className={cn("button-stroke button-small", styles.button)}
                    to={`/bills`}
                  >
                    Bills
                  </Link> */}
                  <button
                      className={cn("button-stroke button-small", styles.button)}
                      onClick={() => setVisibleP2p({ status: true, wallet: "" })}
                    >
                      P2P
                    </button>

                </div>
              ) : <div className={styles.btns}>
                <Link
                  className={cn("button-stroke button-small", styles.button)}
                  to={`/investments`}
                >
                  <span>Invest Now</span>
                </Link>
                <Link
                  className={cn("button-stroke button-small", styles.button)}
                  to={`/my-investments`}
                >
                  <span>View</span>
                </Link>
              </div>}
            </div>
          </div>

        ))}
      </div>
      
    </>
  );
};

export default AccountBalances;
