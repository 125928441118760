import React, { useEffect } from "react";
import styles from "./WalletOverviewDetails.module.sass";
import Wallet from "../../components/Wallet";
import Integrations from "./Integrations"
import { useDispatch } from 'react-redux';
import { updateBills } from "../../store/bills";
import { getBils } from "../../api/bills";

const Bills = () => {
  const dispatch = useDispatch();


  useEffect(() => {
    async function load() {
      const loadBills = await getBils();

      if (loadBills) {
        dispatch(updateBills(loadBills.data));
      }
     
    }
    load();

  }, [dispatch]);


  return (
    <>
      <Wallet>
        <div className={styles.list}>

          <div className={styles.item}>
            <div className={styles.head}>Airtime, Data & Bills</div>
            <div className={styles.body}>

            </div>
          </div>
        </div>
        <Integrations />
      </Wallet>
    </>
  );
};

export default Bills;
