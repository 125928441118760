import React, { useRef } from "react";
import Main from "./Main";
import Popular from "../../components/Popular";
import Steps from "./Steps";

const Home = () => {
    const scrollToRef = useRef(null);

    
    return (
        <>
            <Main scrollToRef={scrollToRef} />
            <Popular classSection="section-bg section-mb0" />
            <Steps />
        </>
    );
};

export default Home;
