import React, { useState, useEffect, useCallback } from "react";
import styles from "./Integrations.module.sass";
import Modal from "../../../components/Modal";
import Investment from "../../../components/Investment";
import Card from "../Card";
import { getInvestment } from "../../../api/investment";

const Integrations = () => {
  const [visibleInvestment, setVisibleInvestment] = useState({ visible: false, selected_id: "" });
  const [rawData, setRawData] = useState([]);
  const [investmentData, setinvestmentData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  useEffect(() => {
    async function load() {
      const loadInvestment = await getInvestment({page: 0, size: 100});
      const sortedData = [];
      if (loadInvestment) {
        if (loadInvestment.data) {
          if (loadInvestment.data.investment) {
            setRawData(loadInvestment.data.investment);
            for (let index = 0; index < loadInvestment.data.investment.length; index++) {
              const invest = loadInvestment.data.investment[index];
  
              sortedData.push({
                uuid: invest.uuid,
                title: invest.title,
                amount: invest.amount,
                return: invest.return,
                button: "Invest now",
                image: invest.image[0],
                image2x: invest.image[1]
              })
            }
            setinvestmentData(sortedData);
          }
        }
      }
    
    }
    load();

  }, [setinvestmentData])

  const handleSelect = useCallback(({ visible, selected_id }) => {
    if (rawData) {
      for (let index = 0; index < rawData.length; index++) {
        if (rawData[index].uuid === selected_id) {
          setSelectedData(rawData[index]);
        }

      }
    }
    setVisibleInvestment({ selected_id, visible: visible })
  }, [rawData])

  return (
    <div className={styles.list}>
      {investmentData.map((x, index) => (
        <Card className={styles.card} item={x} key={index} handleSelect={handleSelect} />
      ))}
      <Modal
        visible={visibleInvestment.visible}
        onClose={() => setVisibleInvestment({ ...visibleInvestment, visible: false })}
      >
        <Investment data={selectedData} />
      </Modal>
    </div>
  );
};

export default Integrations;
