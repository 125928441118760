import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = () => {


  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>Terms of Use</h2>
        </div>
      </div>
      <div className={cn("container", styles.text)}>
        <p><br /></p>
        <h2><strong><span className={styles.span2}>1.1 Purpose</span></strong></h2>
        <p><span className={styles.span1}>RisenSail Limited (&ldquo;RisenSail&rdquo;, &ldquo;we&rdquo; or &ldquo;us&rdquo;) offers automated investment services. Should you choose to become our Client, we will create one or more accounts for you:</span></p>
        <ol>
          <li className={styles.span3}>
            <p><span className={styles.span1}>A Cash Wallet</span></p>
          </li>
          <li className={styles.span3}>
            <p><span className={styles.span1}>An Invested Account</span></p>
          </li>
        </ol>
        <p><span className={styles.span1}>The Cash Wallet is for assets that are liquid and not invested, typically used for emergency or short-term need purposes. The Invested Account provides Users with access to professionally managed asset allocation strategies managed by RisenSail.</span></p>
        <h2><strong><span className={styles.span2}>1.2 Agreement</span></strong></h2>
        <p><span className={styles.span1}>When you access our website at www.risensail.com (&quot;our website&quot; or &quot;this website&quot;) as a User, you&apos;re agreeing to be bound by the following Terms of Use and if you&apos;ve elected to become a Client, you are also bound by our Client Agreement - RisenSail Client Agreement. Please therefore take the time to read the following binding Terms of Use below.</span></p>
        <p><span className={styles.span1}>For the purpose of these Terms of Use, a User is an individual who uses our website to evaluate our services, or for educational purposes and a Client is an individual who signs our Client Agreement that entitles the Client to have his or her Account managed by RisenSail. This Terms of Use, as well as the Privacy Policy:&nbsp;</span><a href="https://risensail.com/privacy-policy"><u><span className={styles.span4}>(https://risensail.com/privacy-policy)</span></u></a><span className={styles.span1}>&nbsp; applies to both Users and Clients. If you elect to become a Client, you will be subject to these Terms of Use, our Client Agreement, our Privacy Policy and any additional terms to which you agree when you create and fund an account.</span></p>
        <p><span className={styles.span1}>This website is available for users globally. Similarly, we make no representations that accessing this website from users&apos; respective location(s) is legal or permissible by extant local law(s). If you access this website from your respective location, , you do so at your own risk and are yourself responsible for compliance with local laws.</span></p>
        <h2><strong><span className={styles.span2}>2.2 Your Access</span></strong></h2>
        <p><span className={styles.span1}>In order to access certain features of this app, you must register to create an account (&quot;User Account&quot;). When you register, you will be asked to choose a password, which you will be required to use to access your User Account. Risensail has physical, electronic and procedural safeguards that comply with regulatory standards to guard Users&apos; and Clients&apos; nonpublic personal information (see Privacy Policy). You are responsible for safeguarding your password and other User Account information.</span><span className={styles.span1}><br /></span><span className={styles.span1}>You agree not to disclose your password to any third party and you will notify Risensail Support immediately if your password is lost or stolen or if you suspect any unauthorized use of your User Account. As a User you agree that you shall be solely responsible for any activities or actions under your User Account, whether or not you have authorized such activities or actions. You agree that the information you provide to us on Account or User Account registration through our website will be true, accurate, current, and complete.</span></p>
        <h2><strong><span className={styles.span2}>2.3 Alerts, Notifications and Service Communications</span></strong></h2>
        <p><span className={styles.span1}>By creating a User Account, you automatically sign up for various types of alerts via email and sms notification. When logged in, you may customize, modify and in some cases deactivate alerts by adjusting the settings accordingly. We never include your password in these communications, but we may include your name, or email address and information about your Account if you are a Client. Anyone with access to your email or mobile device will be able to view these alerts. You may unsubscribe from marketing oriented emails at any time.</span></p>
        <h2><strong><span className={styles.span2}>3.1 Information Provided to User Not Guaranteed</span></strong></h2>
        <p><span className={styles.span1}>You understand and acknowledge that Risensail cannot guarantee any investment results you could obtain from investment information and financial insights it provides prior to you becoming a Client and that Risensail is not liable for these results. All investments entail a risk of loss and that you may lose money. Risensail may offer other investment management services to individuals who become Clients at its sole discretion. Your election to engage our investment management services are subject to your explicit enrollment and acceptance of the separate Client Agreement and related fee schedule.</span></p>
        <h2><strong><span className={styles.span2}>3.2 Disclaimer Warranties</span></strong></h2>
        <p><span className={styles.span1}>Your use of this &nbsp;website, and the personal information you provide is at your sole discretion and risk. This website and all materials, information, products and services included therein, are provided on an AS IS and AS AVAILABLE basis without warranties of any kind from Risensail.</span></p>
        <p><span className={styles.span1}>RISENSAIL EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, RELATING TO THIS WEBSITE, THEIR CONTENT AND/OR USER INFORMATION, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. RISENSAIL DISCLAIMS ANY WARRANTIES, EXPRESS OR IMPLIED:</span></p>
        <ol>
          <li className={styles.li1}>
            <p><span className={styles.span1}>REGARDING THE AVAILABILITY, SECURITY, ACCURACY, RELIABILITY, TIMELINESS AND PERFORMANCE OF THIS WEBSITE, THEIR CONTENT AND/OR USER INFORMATION;</span></p>
          </li>
          <li className={styles.li1}>
            <p><span className={styles.span1}>THAT THIS WEBSITE WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED;</span></p>
          </li>
          <li className={styles.li1}>
            <p><span className={styles.span1}>THAT THIS WEBSITE WILL BE FREE FROM ELECTRONIC VIRUSES; OR</span></p>
          </li>
          <li className={styles.li1}>
            <p><span className={styles.span1}>REGARDING THE PERFORMANCE OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION PROVIDED BY THIS WEBSITE INCLUDING BUT NOT LIMITED TO INFORMATION OBTAINED THROUGH SOCIAL MEDIA.</span></p>
          </li>
        </ol>
        <p><span className={styles.span1}>No advice or information you obtain from this website shall create any warranty not expressly stated in this Agreement. If you choose to rely on such information, you do so solely at your own risk. Some countries or jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above exclusions may not apply to you.</span></p>
        <h2><strong><span className={styles.span2}>3.3 Limitation of Liability</span></strong></h2>
        <p><span className={styles.span1}>IN NO EVENT SHALL RISENSAIL OR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THIS WEBSITE, THEIR CONTENT AND/OR USER INFORMATION, INCLUDING BUT NOT LIMITED TO THE QUALITY, ACCURACY, OR UTILITY OF THE INFORMATION PROVIDED AS PART OF OR THROUGH THIS WEBSITE OR FOR ANY INVESTMENT DECISIONS MADE ON THE BASIS OF SUCH INFORMATION, WHETHER THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT RISENSAIL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.</span></p>
        <p><span className={styles.span1}>You may request termination of your User Account at any time and for any reason by sending an email to contact@risensail.com We may terminate or suspend your access to this app or our website, in our sole discretion, at any time for any reason without notice to you. Further, if we believe, in our sole discretion, that a violation of these Terms of Use has occurred, we may take any other corrective action we deem appropriate. We reserve the right to investigate suspected violations of these Terms of Use. We may seek to gather information from a user who is suspected of violating these Terms of Use (or from any other user) and you agree to provide us with such information. We will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity of anyone posting, publishing, or otherwise making available any User information, emails, or other materials that are believed to violate these Terms of Use.</span></p>
        <p><span className={styles.span1}>Any suspension, termination, or cancellation shall not affect your obligations to Risensail under these Terms of Use (including but not limited to ownership, indemnification, and limitation of liability), which by their sense and context are intended to survive such suspension, termination, or cancellation</span></p>
        <p><span className={styles.span1}>You agree that these Terms of Use shall be governed by and interpreted in accordance with the laws of the Nigeria. Any legal action or proceeding arising under these Terms of Use will be brought exclusively in courts located and the parties hereby irrevocably consent to the personal jurisdiction and venue therein.</span></p>
        <h2><strong><span className={styles.span2}>4.1 Dos</span></strong></h2>
        <p><span className={styles.span1}>You agree you will:</span></p>
        <ul>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Provide accurate information to us, whether reported directly or through a third party who you authorize, and keep it updated;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Use the services solely for your personal, non-commercial use;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Provide accurate and complete information about yourself for your profile and keep your password confidential;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Use the Services in a professional manner</span></p>
          </li>
        </ul>
        <h2><strong><span className={styles.span2}>4.2 Dont&apos;s</span></strong></h2>
        <p><span className={styles.span1}>You agree you will not:</span></p>
        <ul>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Circumvent, disable, or otherwise interfere with security-related features of this &nbsp;website or features that prevent or restrict use or copying of any content or User information;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Upload, email, transmit, provide, or otherwise make available:</span></p>
            <ul>
              <li className={styles.agrementSection}>
                <p><span className={styles.span1}>any User information which you do not have the lawful right to use, copy, transmit, display, or make available (including any User information that would violate any confidentiality or fiduciary obligations that you might have with respect to the User information); or</span></p>
              </li>
              <li className={styles.agrementSection}>
                <p><span className={styles.span1}>any User information that infringes the intellectual property rights of, or violates the privacy rights of, any third-party (including without limitation copyright, trademark, patent, trade secret, or other intellectual property rights, moral right, or right of publicity); or</span></p>
              </li>
              <li className={styles.agrementSection}>
                <p><span className={styles.span1}>unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of solicitation; or</span></p>
              </li>
              <li className={styles.agrementSection}>
                <p><span className={styles.span1}>any personal information that is unlawful, obscene, harmful, threatening, harassing, defamatory, or hateful, or that contain objects or symbols of hate, invade the privacy of any third-party, contain nudity, are deceptive, threatening, abusive, inciting of unlawful action, or are otherwise objectionable in the sole discretion of Risensail; or</span></p>
              </li>
              <li className={styles.agrementSection}>
                <p><span className={styles.span1}>any personal information that contains software viruses or any other computer code, files, or programs designed to (i) interrupt, destroy, or limit the functionality of any computer software; or (ii) interfere with the access of any user, host or network, including without limitation overloading, flooding, spamming, mail-bombing, or sending a virus to this website; or</span></p>
              </li>
              <li className={styles.agrementSection}>
                <p><span className={styles.span1}>any personal information that includes code that is hidden or otherwise surreptitiously contained within the User information;</span></p>
              </li>
            </ul>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Use any meta tags or other hidden text or metadata utilizing a Risensail name, trademark, URL or product name;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Forge any TCP/IP packet header or any part of the header information in any posting, or in any way use this website to send altered, deceptive, or false source-identifying information;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Interfere with or disrupt (or attempt to interfere with or disrupt) any Risensail web page, server, or network, or the technical delivery systems of Risensail&rsquo;s providers, or disobey any requirements, procedures, policies, or regulations of networks connected to this app or our website.</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Attempt to probe, scan, or test the vulnerability of any Risensail system or network or breach or impair or circumvent any security or authentication measures protecting this website;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Attempt to decipher, decompile, disassemble, or reverse-engineer any of the software used to provide this website;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Attempt to access, search, or meta-search this website or content thereon with any engine, software, tools, agent, device, or mechanism other than software and/or search agents provided by this website or other generally available third- party web browsers, including without limitation any software that sends queries this &nbsp;website to determine how a website or web page ranks;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Violate these terms of use or any other rule or agreement applicable to you or Risensail&rsquo;s inclusion in, reference to, or relationship with any third party or third-party site or service, or your use of any such third-party site or service;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Collect or store personal information about other users without their express permission;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Impersonate or misrepresent your affiliation with any person or entity, through pretexting or some other form of social engineering, or commit fraud;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Solicit any User for any investment or other commercial or promotional transaction;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Violate any applicable law, regulation, or ordinance;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Scrape or copy information through any means (including crawlers, browser plugins and add-ons, and any other technology or manual work);</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Use, launch, or permit to be used any automated system, including without limitation &quot;robots,&quot; &quot;crawlers,&quot; or &quot;spiders&quot;; or</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Copy or use the information, content or data on this website in connection with a competitive service (as determined by Risensail);</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Monitor this website&rsquo;s availability, performance or functionality for any competitive purposes;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Use this website or content thereon in any manner not permitted by these Terms of Use;</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Copyrighted Materials: No Unauthorized Use. If you become aware of misuse of this website or content thereon by any person, please contact Risensail to report any abuse.</span></p>
          </li>
          <li className={styles.agrementSection}>
            <p><span className={styles.span1}>Please note that funding your wallet with the sole purpose of withdrawing it in a bid to profit from the fluctuating FX rates is strongly discouraged and may lead to account suspension or being permanently barred.</span><span className={styles.span1}><br /></span><span className={styles.span1}>The Risensail platform should be used strictly for the purpose of participating in the various investment opportunities available on the platform and not as a means to round trip FX rates.</span><span className={styles.span1}><br /></span><span className={styles.span1}>You hereby agree that if we determine that your funding has no investment intent beyond taking advantage of FX rates, your account will be flagged and your funds will be refunded at the original Naira value that was sent or in accordance with the FX rates on the date your account was funded, as determined by Risensail.</span><span className={styles.span1}><br /></span><span className={styles.span1}>This refund process shall be net of all charges incurred. You further agree that if your account is continuously flagged pursuant to the above, your account may be barred permanently without any recourse to you.</span></p>
          </li>
        </ul>
        <p><span className={styles.span1}>In the event that any provision in these Terms of Use is held to be invalid or unenforceable, the remaining provisions will remain in full force and effect. The failure of a party to enforce any right or provision of these Terms of Use will not be deemed a waiver of such right or provision. You may not assign this Agreement (by operation of law or otherwise) without the prior written consent of Risensail, and any prohibited assignment will be null and void. Risensail may assign this Agreement or any rights hereunder without your consent. The relationship of the parties under these Terms of Use is that of independent contractors, and these Terms of Use will not be construed to imply that either party is the agent, employee, or joint venture of the other. We reserve the right to change this Agreement by posting a revised Terms of Use and we agree that changes cannot be retroactive. If you don&apos;t agree with these changes, you must stop using this website. You agree that the only way to provide us legal notice is at the address provided in Section 5.6.</span></p>
        <h2><strong><span className={styles.span2}>5.1 Integration &amp; Severability</span></strong></h2>
        <p><span className={styles.span1}>This Agreement is the entire agreement between you and us with respect to your use of this website, and supersedes all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and us. This Agreement does not govern Risensail&rsquo;s provision of advisory services to you as a Client. If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.</span></p>
        <h2><strong><span className={styles.span2}>5.2 Advice may differ</span></strong></h2>
        <p><span className={styles.span1}>You understand and agree that we act as an investment adviser for Clients and will continue to do so. We and our personnel may make recommendations and take action for Clients that may differ from the recommendations we give to you or the action we take on your behalf. In addition, our personnel may take actions for their own accounts based on their own investment situations that differ from the recommendations we give you or actions we take on your behalf.</span></p>
        <h2><strong><span className={styles.span2}>5.3 Portfolio Aggregation</span></strong></h2>
        <p><span className={styles.span1}>We provide the opportunity for individuals to access high ticket investments at a lower entry point. This means that we will sometimes pool User/Client funds into a portfolio and purchase assets with the portfolio rather than in the names of individual account owners. In all such cases, funds and returns are apportioned to their respective owners according to the amount of their contribution to the portfolio. Users and Clients by using Risensail agree to have their funds pooled with other investors to purchase assets and earn returns.</span></p>
        <h2><strong><span className={styles.span2}>5.4 Communication</span></strong></h2>
        <p><span className={styles.span1}>You agree that these Terms of Use and the rules, restrictions, and policies contained herein, and Risensail&rsquo;s enforcement thereof, are not intended to confer and do not confer any rights or remedies on any person other than you and Risensail. These Terms of Use together with the Risensail Privacy Policy and Client Agreement (if applicable) constitute the entire agreement between Risensail and you with respect to the subject matter hereof. Any notice or other communication to be given hereunder will be in writing and given by</span></p>
        <ol>
          <li className={styles.span3}>
            <p><span className={styles.span1}>Risensail via email (in each case to the address that you provide), or</span></p>
          </li>
          <li className={styles.span3}>
            <p><span className={styles.span1}>You via email to contact@risensail.com or to such other addresses as Risensail may specify in writing. The date of receipt shall be deemed the date on which such notice is transmitted.</span></p>
          </li>
        </ol>
        <h2><strong><span className={styles.span2}>5.5 Feedback</span></strong></h2>
        <p><span className={styles.span1}>Your feedback is welcome and encouraged. You may submit feedback by emailing us at contact@risensail.com. You agree, however, that</span></p>
        <ol>
          <li className={styles.span3}>
            <p><span className={styles.span1}>By submitting unsolicited ideas to Risensail or any of its employees or representatives, by any medium, including but not limited to email, written, or oral communication, you automatically forfeit your right to any intellectual property rights in such ideas; and</span></p>
          </li>
          <li className={styles.span3}>
            <p><span className={styles.span1}>Such unsolicited ideas automatically become the property of Risensail. You hereby assign and agree to assign all rights, titles and interests you have in such feedback and ideas to Risensail together with all intellectual property rights therein. In addition, you warrant that all moral rights in any feedback have been waived, and you do hereby waive any such moral rights.</span></p>
          </li>
        </ol>
        <h2><strong><span className={styles.span2}>5.6 Questions</span></strong></h2>
        <p><span className={styles.span1}>This document constitutes Risensail&apos;s complete Terms of Use for this website and related services. If you have questions about these Terms of Use or about Risensail or content thereon, please contact Risensail at contact@risenail.com.</span></p>
      
      </div>
    </div>
  );
};
export default Main;
