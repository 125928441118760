import React, { useState, useCallback } from "react";
import Login from "../../components/Login";
import FormPhone from "./FormPhone";
import optionsPhone from "../../config/optionsPhone";
import { useSelector, useDispatch } from 'react-redux';
import { addPhoneData } from "../../store/authForm"
const AddPhone = () => {
  const phoneformData = useSelector((state) => state.authForm.addPhone);
  const dispatch = useDispatch();

  const [phone, setPhone] = useState(optionsPhone[0]);


  const phoneFormHandler = useCallback((value) => {
    dispatch(addPhoneData(value));
  }, [dispatch]);

  return (
    <Login
      content="Already added phone"
      linkText="Dashboard"
      linkUrl="/dashboard"
    >
      <FormPhone formHandler={phoneFormHandler} formData={phoneformData} phone={phone} setPhone={setPhone} optionsPhone={optionsPhone}  />
    </Login>
  );
};

export default AddPhone;
