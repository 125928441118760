import React from "react";
import cn from "classnames";
import styles from "./Transfer.module.sass";
import Icon from "../Icon";
import TextInput from "../TextInput";
import cogoToast from 'cogo-toast';
import { sendPhoneEmailToken, verifyPhoneEmail } from "../../api/user";

const Transfer = ({ dispatch, changeverificationData, authForm }) => {
  return (
    <div className={styles.transfer}>
      <div className={cn("h4", styles.title)}>
        <Icon name="arrow-left" size="32" />
        {authForm.channel === "phone" ? "Phone" : "E-mail"} Verification
      </div>
      <div className={styles.wrap}>
        <p onClick={async () => {
          const otpres = await sendPhoneEmailToken({ channel: authForm.channel })
          if (otpres) {
            if (otpres.status === true) {
              cogoToast.success(otpres.message);
            }
          }
        }}>Click here to get your verification code, As part of our efforts to keep you safe and secure</p>
      </div>

      <div className={styles.box}>
        <TextInput
          className={styles.field}
          label="Enter token"
          name="token"
          type="text"
          changeHandler={(val) =>  dispatch(changeverificationData({ name: "token", value: val.value }))}
          value={authForm.token}
          note={`A verification token has been sent to your ${authForm.channel}`}
          required
        />
      </div>
      <button className={cn("button", styles.button)} onClick={async () => {
        const verifyRes = await verifyPhoneEmail({ token: authForm.token });
        if (verifyRes) {
          if (verifyRes.status === true) {
            cogoToast.success(verifyRes.message).then(()=>window.location.reload())
            // dispatch(changeverificationData({ name: "close", value: null }));
          }
        }
      }}>Verify</button>
    </div>
  );
};

export default Transfer;
