import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Main.module.sass";
import { useSelector } from 'react-redux';

const Main = () => {
    const authData = useSelector((state) => state.auth);
    return (
        <div className={cn("section", styles.main)}>
            <div className={cn("container", styles.container)}>
                <div className={styles.wrap}>
                    <h1 className={cn("h1", styles.title)}>
                    Invest in Your Future Today
                    </h1>
                    <div className={styles.text}>
                    Take control of your finances by investing in hundreds of local and foreign investment opportunities and earn up to 30% return on investment with Risensail.
                    </div>
                    <Link className={cn("button", styles.button)} to={authData.token ? "/dashboard" : "/sign-up"}>
                    {authData.token ? "Dashboard" : "Get started now"}
                    </Link>
                </div>
                <div className={styles.bg}>
                    <img
                        srcSet="/images/home_main.png"
                        src="/images/home_main.png"
                        alt="Cards"
                    />
                </div>
            </div>
        </div>
    );
};

export default Main;
