import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Verification.module.sass";
import TextInput from "../../../components/TextInput";
import Message from "../../../components/Message";
import Loader from "../../../components/Loader";
import { resetPasswordToken, resetPassword } from "../../../api/auth";

const Verification = ({ formHandler, formData }) => {
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const [displayLoader, setDisplayLoad] = useState(false);
  useEffect(() => {
    async function loader() {
      let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (formData.email.match(regex)) {
        const resetPasswordTokenRes = await resetPasswordToken({
          email: formData.email
        });

        if (resetPasswordTokenRes.status) {
          setMessage({ display: true, error: false, message: resetPasswordTokenRes.message });
        } else {
          setMessage({ display: true, error: true, message: resetPasswordTokenRes.message });
        }

      } else {
        setMessage({ display: true, error: true, message: "Email must be a valid email" });
      }
    }
    loader();
  }, [formData.email])
  return (
    <form className={styles.form}>
      <h3 className={cn("h3", styles.title)}>Security verification</h3>
      <div className={styles.info}>
        To secure your account, please complete the following verification.
      </div>
      <div className={styles.text}>
        Enter the verification token sent to <span>{formData.email}</span>
      </div>
      <div className={styles.fieldset}>
        <Message display={message.display} error={message.error} message={message.message} />

        <TextInput
          className={styles.field}
          label="Token"
          name="token"
          type="text"
          changeHandler={formHandler}
          placeholder="Enter token"
          value={formData.token}
          required
        />
        <TextInput
          className={styles.field}
          label="new password"
          name="password"
          type="password"
          changeHandler={formHandler}
          placeholder="Password"
          required
          value={formData.password}
          view
        />
      </div>

      <div className={styles.btns}>
        <button className={cn("button-stroke button-small", styles.button)} onClick={() => {

        }}>
          Resend code
        </button>
        <button type="button" className={cn("button-small", styles.button)} onClick={async () => {
          setDisplayLoad(true)
          const resetPasswordRes = await resetPassword({
            token: formData.token,
            password: formData.password
          })

          if (resetPasswordRes.status) {
            setMessage({ display: true, error: false, message: resetPasswordRes.message });
            setTimeout(() => {
              window.location.href = "/sign-in";
            }, 1000);

          } else {
            setMessage({ display: true, error: true, message: resetPasswordRes.message });
            setDisplayLoad(false);
          }

        }}>
          {displayLoader ? <Loader color="white" /> : " Reset password"}
        </button>
      </div>
    </form>
  );
};

export default Verification;
