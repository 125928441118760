import React, { useEffect, useState, useCallback } from "react";
import cn from "classnames";
import styles from "./Confirm.module.sass";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import { sendEmailOtp, signUp } from "../../../api/auth";
import cogoToast from 'cogo-toast';
import { logIn } from "../../../store/auth";
import { useDispatch } from 'react-redux';
import Message from "../../../components/Message";
import Loader from "../../../components/Loader";
import {
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

const Confirm = ({ goNext, formHandler, formData, referral }) => {
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const [displayForm, setDisplayForm] = useState(false);
  const [displayBack, setDisplayBack] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(true);
  const [reCapToken, setreCapToken] = useState();
  const dispatch = useDispatch()
  useEffect(() => {
    async function load() {
      const regex = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$");
      if (regex.test(formData.email)) {
        // send email
        setTimeout(async () => {
          const sendOtpRes = await sendEmailOtp({
            email: formData.email,
            reCapToken
          });
          if (sendOtpRes.status === true) {
            setDisplayForm(true);
            setMessage({ display: true, error: false, message: sendOtpRes.message });
            setDisplayLoader(false)

          } else {
            setMessage({ display: true, error: true, message: sendOtpRes.message });
            setDisplayLoader(false)
          }

        }, 1000);
      } else {
        cogoToast.error("Please enter a valide email address");
      }
    }
    load();
  }, [formData.email, reCapToken])

  const captchaChange = useCallback((value) => {
    setreCapToken(value)
  }, []);

  return (
    <div className={styles.form}>
      <h3 className={cn("h4", styles.title)}>Let’s confirm it’s really you</h3>

      <Message display={message.display} error={message.error} message={message.message} />
      {
        displayForm ?
          <>
            <div className={styles.info}>
              Help us secure your account. <br></br>Your verifications
              Token has been send to <strong>{formData.email}</strong>
            </div>
            <div className={styles.container}>

              <TextInput
                className={styles.field}
                label="Verification Token"
                name="token"
                type="password"
                value={formData.token}
                changeHandler={formHandler}
                placeholder="Token"
                required
                view
              />
              <GoogleReCaptcha onVerify={captchaChange} />
            </div>
            {displayBack ?
              <button className={cn("button", styles.button)} onClick={() => goNext(0)}>
                <Icon name="arrow-left" size="16" />
                <span>Go back</span>

              </button> :

              <button className={cn("button", styles.button)} onClick={async () => {
                const otpRes = await signUp({
                  firstName: formData.firstName,
                  lastName: formData.lastName,
                  email: formData.email,
                  token: formData.token,
                  password: formData.password,
                  refCode: referral
                });
                if (otpRes) {
                  if (otpRes.status === true) {
                    dispatch(logIn(otpRes.data));
                    setMessage({ display: true, error: false, message: otpRes.message });
                    window.location.href = "/add-phone"
                  } else {
                    setDisplayBack(true);
                    setMessage({ display: true, error: true, message: otpRes.message });
                  }
                }
              }}>
                <span>Continue</span>
                <Icon name="arrow-right" size="16" />
              </button>

            }




          </>
          : displayLoader ? <>
            <div className={styles.loader}>
              <Loader />
            </div>
          </> :
            <button className={cn("button", styles.button)} onClick={() => goNext(0)}>
              <Icon name="arrow-left" size="16" />
              <span>Go back</span>

            </button>

      }

    </div>
  );
};

export default Confirm;
