import React from "react";
import styles from "./WalletOverviewDetails.module.sass";
import Wallet from "../../components/Wallet";
import Integrations from "./Integrations";


const MyInvestments = () => {
 
  return (
    <>
      <Wallet>
        <div className={styles.list}>

          <div className={styles.item}>
            <div className={styles.head}>My Investments</div>
            <div className={styles.body}>
              
            </div>
          </div>
        </div>
        <Integrations />
      </Wallet>
    </>
  );
};

export default MyInvestments;
