import React from "react";
import cn from "classnames";
import styles from "./Card.module.sass";

const Card = ({ className, item }) => {
  return (
    <div className={cn(className, styles.card)}>
      <div className={styles.preview}>
        <img src={item.image[0]} alt="Card" />
      </div>
      <div className={styles.title}>{item?.title}</div>
      <div className="">
        <div className={styles.summaryRow}>
          <div className={styles.summaryContent} >
            <p >
              Returns
            </p>
            <p>
            ₦ {parseInt(item.return).toLocaleString("en-US")}
            </p>
          </div>
          <div className={styles.summaryContent} >
            <p >
              Amount
            </p>
            <p>
            ₦ {parseInt(item.amount).toLocaleString("en-US")} 
            </p>
          </div>
        </div>

        <div className={styles.summaryRow}>
        <div className={styles.summaryContent} >
            <p >
              Start
            </p>
            <p>
            {item.startDate}
            </p>
          </div>
          <div className={styles.summaryContent} >
            <p >
              Maturity
            </p>
            <p>
            {item.endDate}
            </p>
          </div>
          
        </div>


      </div>
     
    </div>
  );
};

export default Card;
