import React from "react";
import styles from "./Message.module.sass";

const Message = ({ display, error = false, message }) => {
  return (
    <>
      {
        display ?
          <p className={!error ? styles.success : styles.error}>
            {message}
          </p>
          :
          <></>
      }
    </>

  );
};

export default Message;
