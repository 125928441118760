import React, { useState, useEffect } from "react";
import styles from "./Integrations.module.sass";
import Card from "../Card";
import { myInvestment } from "../../../api/investment";

const Integrations = () => {
  const [rawData, setRawData] = useState([]);
  useEffect(() => {
    async function load() {
      const loadInvestment = await myInvestment();
      console.log(loadInvestment);
      if (loadInvestment) {
        if (loadInvestment.data) {
          if (loadInvestment.data.investment) {
            setRawData(loadInvestment.data.investment);
          }
        }
      }

    }
    load();
  }, [])


  return (
    <div className={styles.list}>
      {rawData.length > 0 ? rawData.map((x, index) => (
        <Card className={styles.card} item={x} key={index} />
      )) : <div style={{
        marginLeft: "10%"
      }}>You have no Investment </div>
      }
    </div>
  );
};

export default Integrations;
