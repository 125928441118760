import React, { useState } from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import styles from "./AdminLayout.module.sass";
import Icon from "../Icon";
import Modal from "../Modal";
import Withdraw from "../Withdraw";
import DepositModel from "../DepositModel";

const navigation = [
  {
    title: "Dashboard",
    icon: "laptop",
    url: "/admin"
  },
  {
    title: "Investments",
    icon: "cloud",
    url: "/admin/investments",
  },
  {
    title: "Transaactions",
    icon: "coin",
    url: "/admin/transactions",
  },
  {
    title: "Users",
    icon: "user",
    url: "/admin/users",
  }
];

const AdminLayout = ({ className, children }) => {
  const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  const [visibleTransfer, setVisibleTransfer] = useState(false);
  const [visibleDeposit, setVisibleDeposit] = useState(false);

  return (
    <>
      <div className={cn(className, styles.wallet)}>
        <div className={styles.sidebar}>
          <div className={cn(styles.group, { [styles.active]: false })}>
            <div className={styles.menu}>
              {navigation.map((item, index) =>
                item.url ? (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.separator]: item.separator,
                    })}
                    activeClassName={styles.active}
                    to={item.url}
                    key={index}
                  >
                    {item.color && (
                      <div
                        className={styles.bg}
                        style={{ backgroundColor: item.color }}
                      ></div>
                    )}
                    {item.icon && <Icon name={item.icon} size="20" />}
                    {item.title}
                  </NavLink>
                ) : (
                  <button
                    className={cn(styles.item, {
                      [styles.separator]: item.separator,
                    })}
                    key={index}
                    onClick={() => setVisibleTransfer(!visibleTransfer)}
                  >
                    {item.icon && <Icon name={item.icon} size="20" />}
                    {item.title}
                  </button>
                )
              )}
            </div>
          </div>
          
        </div>
        <div className={styles.wrapper}>{children}</div>
      </div>
      <Modal
        visible={visibleWithdraw}
        onClose={() => setVisibleWithdraw(false)}
      >
        <Withdraw />
      </Modal>
      {/* <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <P2p />
      </Modal> */}
      <Modal
        visible={visibleDeposit}
        onClose={() => setVisibleDeposit(false)}
      >
        <DepositModel />
      </Modal>
    </>
  );
};

export default AdminLayout;
