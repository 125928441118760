import React, { useState, useEffect, useCallback } from "react";
import cn from "classnames";
import styles from "./Bills.module.sass";
import Icon from "../Icon";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import Loader from "../Loader";
import Message from "../Message";
import { paybills, verifyBills, getBils } from "../../api/bills";
import { useDispatch } from 'react-redux';
import { profile } from "../../api/profile";
import { addWallet } from "../../store/wallets";

const Bills = () => {
  const [rawData, setRawData] = useState([]);
  const [seletedBills, setSelectedBills] = useState("");
  const [seletedPlan, setSelectedPlan] = useState("");
  const [billsCat, setBillsCat] = useState([]);
  const [planCat, setPlanCat] = useState([]);
  const [thePlan, setThePlan] = useState([]);
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visiblePlan, setVisiblePlan] = useState(true);
  const [formData, setFormData] = useState({ amount: "", biller: "", plan: "", device: "", pin: "", address: "", customerName: "" });
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const [displayLoader, setDisplayLoad] = useState(false); const dispatch = useDispatch();
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const loader = async () => {
      const profileRes = await profile();
      if (profileRes) {
        if (profileRes.status) {
          dispatch(addWallet(profileRes.data?.wallets));
          if (profileRes.data?.wallets.length > 0) {
            for (let index = 0; index < profileRes.data?.wallets.length; index++) {
              if (profileRes.data?.wallets[index].title === "Available") {
                setBalance(profileRes.data?.wallets[index].amount);
              }

            }
          }
        }
      }

      const getBilsRes = await getBils();

      if (getBilsRes) {
        if (getBilsRes.data) {
          setRawData(getBilsRes.data.bills);
          if (getBilsRes.data.bills) {
            setBillsCat(Object.keys(getBilsRes.data.bills))
            setSelectedBills(Object.keys(getBilsRes.data.bills)[0])
          }
        }
      }

    }

    loader();

  }, [dispatch, setBillsCat, setSelectedBills])

  const billerHandler = useCallback((input) => {
    setSelectedBills(input);
    const theCat = rawData[input]
    theCat.data.length === 1 ? setVisiblePlan(false) : setVisiblePlan(true);
    const sortedPlan = [];
    for (let index = 0; index < theCat.data.length; index++) {
      sortedPlan.push(theCat.data[index].title);
    }
    setPlanCat(sortedPlan);
    setSelectedPlan(sortedPlan[0]);
    for (let i = 0; i < theCat.data.length; i++) {
      if (theCat.data[i].title === sortedPlan[0]) {
        setThePlan(theCat.data[i]);
        setFormData({ ...formData, amount: theCat.data[i].price, biller: input, plan: theCat.data[i].code });
      }

    }
  }, [rawData, formData])

  const preview = useCallback(async () => {

    setDisplayLoad(true)
    const payload = {
      itemCode: thePlan?.providers?.flw?.item_code,
      billersCode: thePlan?.providers?.flw?.biller_code,
      cunstomer: formData.device
    }
    const verifyBillsRes = await verifyBills(payload);
    if (verifyBillsRes.status) {
      setMessage({ display: true, error: false, message: verifyBillsRes.message });
      setFormData({ ...formData, address: verifyBillsRes.data?.address, customerName: verifyBillsRes.data?.name });
      setVisiblePreview(true)
      setDisplayLoad(false)
    } else {
      setMessage({ display: true, error: true, message: verifyBillsRes.message });
      setDisplayLoad(false)
    }

  }, [thePlan, formData]);

  const planHandler = useCallback((input) => {

    const theCat = rawData[formData.biller];
    for (let i = 0; i < theCat.data.length; i++) {
      if (theCat.data[i].title === input) {
        setSelectedPlan(theCat.data[i].title);
        setThePlan(theCat.data[i]);
        setFormData({ ...formData, amount: theCat.data[i].price, plan: theCat.data[i].code })
      }
    }

  }, [formData, rawData])


  const submitHandler = useCallback(async () => {
    setDisplayLoad(true);
    const paybillsRes = await paybills(formData);
    if (paybillsRes) {
      if (paybillsRes.status === true) {
        setMessage({ display: true, error: false, message: paybillsRes.message });
        setDisplayLoad(false);
        setTimeout(function(){ window.location.reload()}, 2000);
      } else {
        setMessage({ display: true, error: true, message: paybillsRes.message });
        setDisplayLoad(false);
      }
    }
  }, [formData])

  return (
    <div className={styles.transfer}>
      <div className={cn("h4", styles.title)}>
        <Icon name="arrow-left" size="32" />
        Pay Bills
      </div>
      {billsCat.length === 0 ?
        <div className={styles.loderDiv}>
          <Loader />
        </div>
        :

        <>
          <div className={styles.wrap}>
            <div className={styles.category}>
              Available balance
            </div>
            <div className={styles.details}>
              <div className={styles.currency}>₦ {parseInt(balance).toLocaleString("en-US")}</div>
            </div>
          </div>
          <Message display={message.display} error={message.error} message={message.message} />


          {!visiblePreview ? <>
            <div className={styles.field}>
              <Dropdown
                className={styles.dropdown}
                label="Select Biller"
                value={seletedBills}
                setValue={billerHandler}
                options={billsCat}
              />
            </div>
            {
              visiblePlan ? <div className={styles.field}>
                <Dropdown
                  className={styles.dropdown}
                  label="Select Plan"
                  value={seletedPlan}
                  setValue={planHandler}
                  options={planCat}
                />
              </div> : <></>
            }
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                label="Device Id"
                name="device"
                value={formData.device}
                type="text"
                changeHandler={(e) => {
                  setFormData({ ...formData, device: e.value });
                }}
                required
              />

            </div>
            <div className={styles.box}>
              <TextInput
                className={styles.field}
                label="Amount"
                name="amount"
                value={formData.amount}
                type="number"
                changeHandler={(e) => {
                  setFormData({ ...formData, amount: e.value });
                }}
                required
              />

            </div>
            <button onClick={() => preview()} className={cn("button", styles.button)}>{displayLoader ? <Loader color="white" /> : "Preview details"}</button>
          </> :
            <>
              <div className={styles.wrap}>
                <div className={styles.category}>
                  Device:  <br /> Amount:  <br /> Name:  <br /> Address:
                </div>
                <div className={styles.category}>
                  {formData.device}  <br />  ₦ {parseInt(formData.amount || 0).toLocaleString("en-US")} <br />  {formData.customerName} <br />  {formData.address}
                </div>
              </div>

              <div className={styles.box}>
                <TextInput
                  className={styles.field}
                  label="Transaction Pin"
                  name="pin"
                  value={formData.pin}
                  type="password"
                  changeHandler={(e) => {
                    setFormData({ ...formData, pin: e.value });
                  }}
                  required
                  view
                />

              </div>
              <button onClick={() => submitHandler()} className={cn("button", styles.button)}>{displayLoader ? <Loader color="white" /> : "Pay Now"}</button>
            </>
          }
        </>
      }
    </div>
  );
};

export default Bills;
