import React, { useState } from "react";
import cn from "classnames";
import styles from "./Integrations.module.sass";
import Modal from "../../../components/Modal";
import Airtime from "../../../components/Airtime";
import Data from "../../../components/Data";
import Bills from "../../../components/Bills";
import { useSelector } from 'react-redux';

const Integrations = () => {
  const [visibleAirtime, setVisibleAirtime] = useState(false);
  const [visibleData, setVisibleData] = useState(false);
  const [visibleBills, setVisibleBills] = useState(false);
  const billsData = useSelector((state) => state.bills);
  const items = [
    {
      title: "Airtime",
      content:
        "Top-up your line for calls & sms",
      setValue: setVisibleAirtime,
    },
    {
      title: "Data",
      content:
        "Buy data to stay connected",
      setValue: setVisibleData,
    },
    {
      title: "Bills",
      content:
        "Pay your bills",
      setValue: setVisibleBills,
    }
  ];

  return (
    <div className={styles.list}>
      {items.map((x, index) => (
        <div className={styles.item} key={index}>
          <div className={styles.head}>
            <div className={styles.title}>{x.title}</div>
            <div className={styles.content}>{x.content}</div>
          </div>
          <div className={styles.body}>
            <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={() => x.setValue(true)}
            >
              {x.title === `Bills` ? 'Pay' : 'Buy'} {x.title}
            </button>
          </div>
        </div>
      ))}
      <Modal
        visible={visibleAirtime}
        onClose={() => setVisibleAirtime(false)}
      >
        <Airtime data={billsData?.bills?.airtime_data} />
      </Modal>
      <Modal
        visible={visibleData}
        onClose={() => setVisibleData(false)}
      >
        <Data data={billsData?.bills?.airtime_data} />
      </Modal>
      <Modal
        visible={visibleBills}
        onClose={() => setVisibleBills(false)}
      >
        <Bills data={billsData?.bills?.bills}/>
      </Modal>
    </div>
  );
};

export default Integrations;
