import React, { useState, useCallback } from "react";
import cn from "classnames";
import styles from "./TransactionHistory.module.sass";
import TransactionDetails from "../TransactionDetails";
import Modal from "../Modal";
const TransactionHistory = ({ transaction, user, currency }) => {
  const [visible, setVisible] = useState(false);
  const [referenceDetail, setReferenceDetail] = useState("");
  const handleSelect = useCallback((reference) => {
    setReferenceDetail(reference)
    setVisible(true)
  }, [setReferenceDetail, setVisible])

  if (!transaction) {
    return <></>
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Amount / Descriptions</div>
          <div className={styles.col}> Reference </div>
          <div className={styles.col}> Data/Time</div>
        </div>
        {transaction.length > 0 ? transaction.map(({ id, description, reference, amount, status, created_at, peeUser }) => (

          <div
            onClick={() => handleSelect(reference)}
            className={styles.row}
            key={id}
          >
            <div className={styles.col}>
              <div className={styles.currency}>
                <div className={styles.icon}>
                  <div className={cn(amount < 0 ? "category-red" : "category-green", styles.category)}>{amount < 0 ? "⇽" : "⇾"}</div>
                </div>
                <div className={styles.details}>
                  <div className={styles.info}> {currency} {parseInt(amount).toLocaleString("en-US")}</div>
                  <div className={styles.text}>{description}</div>
                </div>
              </div>
            </div>

            <div className={styles.col}>
              <div className={styles.text}>{reference}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.text}>{created_at}</div>
            </div>
          </div>
        )) : <>
          <div style={{
            marginLeft: "10%"
          }}>You have no Transaction </div>
        </>}
      </div>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <TransactionDetails reference={referenceDetail} />
      </Modal>
    </div>
  );
};

export default TransactionHistory;
