import React from "react";
import Faq from "./Faq";

const Contact = () => {
  return (
    <>
      <Faq />
    </>
  );
};

export default Contact;
