import React, { useState, useCallback } from "react";
import styles from "./Investment.module.sass";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import { invest } from "../../api/investment";
import Message from "../Message";
import Loader from "../Loader";
const payOutType = ["Start", "End"];

const Investments = ({ data }) => {
  const [formData, setFormData] = useState({ investmentId: data.uuid, unit: "", payOutType: payOutType[0], pin: "" });
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const [displayLoader, setDisplayLoad] = useState(false);


  const handleFormInput = useCallback((input) => {
    if (!input.name) {
      setFormData({ ...formData, payOutType: input });
    } else if (input.name === "unit") {
      setFormData({ ...formData, unit: input.value });
    } else if (input.name === "pin") {
      setFormData({ ...formData, pin: input.value });
    }
  }, [formData])

  const handleSubmit = useCallback(async () => {
    setDisplayLoad(true);
    const investRes = await invest(formData);
    if (investRes) {
      if (investRes.status === true) {
        setMessage({ display: true, error: false, message: investRes.message });
        setDisplayLoad(false);
      } else {
        setMessage({ display: true, error: true, message: investRes.message });
        setDisplayLoad(false);
      }
    } else {
      setDisplayLoad(false);
    }
    setFormData({ ...formData, pin: "" });
  }, [formData, setMessage, setDisplayLoad])

  return (
    <div className={styles.card}>
      <div className={styles.preview}>
        <img src={data.image[0]} alt="Card" />
      </div>
      <div className={styles.summaryDetails}>
        <div className={styles.summaryDetailsContent} >
          <p className={styles.title}>
            {data.title}
          </p>
        </div>
        <div className={styles.summaryDetailsContent} >
          <p >
            ₦ {parseInt(data.amount).toLocaleString("en-US")} /unit
          </p>
        </div>
      </div>
      <p className={styles.pSum}>
        Investment Summary
      </p>
      <div className="">
        <div className={styles.summaryRow}>
          <div className={styles.summaryContent} >
            <p >
              Expected Returns
            </p>
            <p className={styles.returnText}>
              {data.return}%
            </p>
          </div>
          <div className={styles.summaryContent} >
            <p >
              Investment Type
            </p>
            <p className={styles.returnText}>
              Fixed Income
            </p>
          </div>
        </div>

        <div className={styles.summaryRow}>
          <div className={styles.summaryContent} >
            <p >
              Unit available
            </p>
            <p className={styles.returnText}>
              {data.unit} Units
            </p>
          </div>
          <div className={styles.summaryContent} >
            <p >
              Start Date
            </p>
            <p className={styles.returnText}>
              {data.startDate}
            </p>
          </div>
        </div>

        <div className={styles.summaryRow}>
          <div className={styles.summaryContent} >
            <p >
              Maturity Date
            </p>
            <p className={styles.returnText}>
              {data.endDate}
            </p>
          </div>
          <div className={styles.summaryContent} >
            <p >
              Payout Type
            </p>
            <p className={styles.returnText}>
              {data.unitType === "sell" ? "Can be sold" : "Can't be sold"}
            </p>
          </div>
        </div>


      </div>

      <div className={styles.formSection}>
        <Message display={message.display} error={message.error} message={message.message} />

        <div className={styles.field}>
          <Dropdown
            className={styles.dropdown}
            label="Select payout type"
            value={formData.payOutType}
            setValue={handleFormInput}
            options={payOutType}
            h
          />
        </div>
        <div className={styles.box}>
          <TextInput
            className={styles.field}
            label="Number of Unit"
            name="unit"
            type="number"
            value={formData.unit}
            required
            changeHandler={handleFormInput}
          />
        </div>
        <div className={styles.box}>
          <TextInput
            className={styles.field}
            label="Transaction pin"
            name="pin"
            type="password"
            value={formData.pin}
            required
            changeHandler={handleFormInput}
            view
          />
        </div>
        <button onClick={() => handleSubmit()} className={styles.button}>{displayLoader ? <Loader color="white" /> : "Invest now"}</button>
      </div>

    </div>
  );

};

export default Investments;
