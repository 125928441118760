import React from "react";
import Main from "./Main";

const PrivacyPolicy = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default PrivacyPolicy;
