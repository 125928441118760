import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { loadAuth } from "./store/auth";
import { useDispatch, Provider } from 'react-redux';
import "./styles/app.sass";
import axios from "axios";
import { store } from './store/store'
import Page from "./components/Page";
import Home from "./screens/Home";
import About from "./screens/About";
import Faq from "./screens/Faq";
import WalletOverview from "./screens/WalletOverview";
import Transactions from "./screens/Transactions";
import ProfileInfo from "./screens/ProfileInfo";
import Banks from "./screens/Banks";
import Referrals from "./screens/Referrals";
import TwoFa from "./screens/TwoFa";
import AuthorizationPin from "./screens/AuthorizationPin";
import ChangePassword from "./screens/ChangePassword";
import SignIn from "./screens/SignIn";
import SignUp from "./screens/SignUp";
import ForgotPassword from "./screens/ForgotPassword";
import Tearms from "./screens/Tearms";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Bills from "./screens/Bills";
import Investments from "./screens/Investments";
import MyInvestments from "./screens/MyInvestments";
import history from './history'
import AddPhone from "./screens/AddPhone";
import SetTransactionPin from "./screens/SetTransactionPin";
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';
import Dashboard from "./screens/admins/Dashboard";
import Users from "./screens/admins/Users";
import AdminTransactions from "./screens/admins/Transactions";
import AdminInvestments from "./screens/admins/Investments";
const partan = /sign/;

function App() {
  const dispatch = useDispatch()
  const [isAuth, setIsAuth] = useState(false)
  useEffect(() => {
    const localState = localStorage.getItem('token');
    const localStateProfile = localStorage.getItem('profile');
    if (localState) {
      if (localStateProfile) {
        const authData = JSON.parse(localStateProfile);
        dispatch(loadAuth({ ...authData, token: localState }));
        setIsAuth(true);
      }
    }
  }, [dispatch]);

  const PrivateRoute = (props) => {
    const location = useLocation()
    if (location.pathname === "/paystack/callback" || location.pathname === "/flutterwave/callback") {
      axios.get(`https://wallet.risensail.com/webhooks${location.pathname}${location.search}`).then(() => {
        window.location.href = "/dashboard"
      })
    }

    if (isAuth === true) {
      if (partan.test(location.pathname) === true) {
        return <Redirect
          to={{
            pathname: "/dashboard"
          }}
        />
      } else {
        return <Route {...props} />
      }
    } else {
      return <Route {...props} />
    }
  }

  return (
    <Provider store={store}>
      <GoogleReCaptchaProvider reCaptchaKey="6LfOy0YqAAAAABOYyvfKatd38dc-It48hIg3XqIk">
        <React.StrictMode>
          <Router >
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Page>
                    <Home history={history} />
                  </Page>
                )}
              />
              <Route
                exact
                path="/change-password"
                render={() => (
                  <Page>
                    <ChangePassword history={history} />
                  </Page>
                )}
              />
              <Route
                exact
                path="/sign-in"
                render={() => (
                  <Page headerHide footerHide>
                    <SignIn history={history} />
                  </Page>
                )}
              />
              <Route
                exact
                path="/terms-of-use"
                render={() => (
                  <Page >
                    <Tearms history={history} />
                  </Page>
                )}
              />
              <Route
                exact
                path="/privacy-policy"
                render={() => (
                  <Page >
                    <PrivacyPolicy history={history} />
                  </Page>
                )}
              />
              <Route
                exact
                path="/sign-up"
                render={() => (
                  <Page headerHide footerHide>
                    <SignUp history={history} />
                  </Page>
                )}
              />
              <Route
                exact
                path="/forgot-password"
                render={() => (
                  <Page headerHide footerHide>
                    <ForgotPassword history={history} />
                  </Page>
                )}
              />


              {/* Auth routes */}
              <PrivateRoute isAuth={isAuth}>

                <Route
                  exact
                  path="/"
                  render={() => (
                    <Page>
                      <Home history={history} />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/change-password"
                  render={() => (
                    <Page>
                      <ChangePassword history={history} />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/sign-in"
                  render={() => (
                    <Page headerHide footerHide>
                      <SignIn history={history} />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/sign-up"
                  render={() => (
                    <Page headerHide footerHide>
                      <SignUp history={history} />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/add-phone"
                  render={() => (
                    <Page headerHide footerHide>
                      <AddPhone />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/set-pin"
                  render={() => (
                    <Page headerHide footerHide>
                      <SetTransactionPin />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/forgot-password"
                  render={() => (
                    <Page headerHide footerHide>
                      <ForgotPassword />
                    </Page>
                  )}
                />
                {/* Auth routes */}

                <Route
                  exact
                  path="/investments"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <Investments dashboard={true} />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/my-investments"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <MyInvestments />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/bills"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <Bills />
                    </Page>
                  )}
                />


                <Route
                  exact
                  path="/about"
                  render={() => (
                    <Page>
                      <About />
                    </Page>
                  )}
                />

                <Route
                  exact
                  path="/faq"
                  render={() => (
                    <Page>
                      <Faq />
                    </Page>
                  )}
                />

                <Route
                  exact
                  path="/dashboard"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <WalletOverview />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/transactions"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <Transactions />
                    </Page>
                  )}
                />

                <Route
                  exact
                  path="/profile-info"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <ProfileInfo />
                    </Page>
                  )}
                />

                <Route
                  exact
                  path="/banks"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <Banks />
                    </Page>
                  )}
                />


                <Route
                  exact
                  path="/referrals"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <Referrals />
                    </Page>
                  )}
                />

                <Route
                  exact
                  path="/google-auth"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <TwoFa />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/authorization-pin"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <AuthorizationPin />
                    </Page>
                  )}
                />

                {/* Admin routes */}
                <Route
                  exact
                  path="/admin"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <Dashboard />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/admin/users"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <Users />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/admin/transactions"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <AdminTransactions />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/admin/investments"
                  render={() => (
                    <Page headerWide footerHide dashboard={true}>
                      <AdminInvestments />
                    </Page>
                  )}
                />
              </PrivateRoute>

            </Switch>

          </Router>
        </React.StrictMode>
      </GoogleReCaptchaProvider>
    </Provider>
  );
}

export default App;
