import React from "react";
import cn from "classnames";
import styles from "./AdminUsers.module.sass";
const AdminUsers = ({ allUser }) => {

  if (!allUser) {
    return <></>
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Full Name / Tag</div>
          <div className={styles.col}>Email</div>
          <div className={styles.col}>Phone</div>
          <div className={styles.col}>Country/ Type </div>
          <div className={styles.col}> Date/Time</div>
          <div className={styles.col}> Actions</div>
        </div>
        {allUser?.users.length > 0 ? allUser?.users.map(({ id, firstName, lastName, tag, phone, email, avatar, type, email_verified_at, phone_verified_at, country, created_at }) => (

          <div
            className={styles.row}
            key={id}
          >
            <div className={styles.col}>
              <div className={styles.currency}>
                <div className={styles.icon}>
                  <img src={avatar ? avatar[0] : "/images/content/avatar-user.jpg"} alt="Avatar" />
                </div>
                <div className={styles.details}>
                  <div className={styles.info}> {firstName} {lastName}</div>
                  <div className={styles.text}>{tag}</div>
                </div>
              </div>
            </div>

            <div className={styles.col}>
              <div className={styles.info}> {email}</div>
              <div className={styles.text}>{email_verified_at}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.info}> {phone}</div>
              <div className={styles.text}>{phone_verified_at}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.info}> {country}</div>
              <div className={styles.text}>{type}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.text}>{created_at}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.colAct}>

                <button
                  className={cn("button-stroke button-small", styles.buttonGreen)}
                >
                  <span>Invest</span>
                </button>

                <button
                  className={cn("button-stroke button-small", styles.buttonBlue)}
                >
                  <span>bills</span>
                </button>
                <button
                  className={cn("button-stroke button-small", styles.button)}
                >
                  <span>Trans</span>
                </button>
                <button
                  className={cn("button-stroke button-small", styles.buttonYellow)}
                >
                  <span>Edit</span>
                </button>
              </div>
              <div className={styles.colAct}>
                <button
                  className={cn("button-stroke button-small", styles.buttonRedLight)}
                >
                  <span>Pwsr</span>
                </button>
                <button
                  className={cn("button-stroke button-small", styles.buttonYellow)}
                >
                  <span>Ban</span>
                </button>
                <button
                  className={cn("button-stroke button-small", styles.button)}
                >
                  <span>UnBan</span>
                </button>
                <button
                  className={cn("button-stroke button-small", styles.buttonRed)}
                >
                  <span>Delete</span>
                </button>
              </div>
            </div>

          </div>
        )) : <>
          <div style={{
            marginLeft: "10%"
          }}>No users </div>
        </>}
      </div>
    </div>
  );
};

export default AdminUsers;
