import React, { useState, useEffect } from "react";
import styles from "./Transactions.module.sass";
import AdminLayout from "../../../components/AdminLayout";
import AdminTransaction from "../../../components/AdminTransaction";
import { allTransactions } from "../../../api/admin/transactions";
import { Bars } from 'react-loader-spinner'

const Transactions = () => {
  const [alltransaction, setAlltransaction] = useState([]);
 
  useEffect(() => {

    async function load(){
      const allTransactionsRes = await allTransactions({page:0, size: 100 });
      
      if (allTransactionsRes) {
        if (allTransactionsRes.status === true) {
          setAlltransaction(allTransactionsRes.data)
        }
      }
    }
    load();

  }, []);

  if (alltransaction.length === 0) {
    return <>
      <AdminLayout>
        <div className={styles.top}>
          <Bars
            height="80"
            width="180"
            color="#3772FF"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />

        </div>

      </AdminLayout>
    </>
  }
  return (
    <>
      <AdminLayout>
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.head}>All Transactions</div>
            <div className={styles.body}>
              <AdminTransaction alltransaction={alltransaction} />
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default Transactions;
