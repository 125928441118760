import React, { useState, useCallback } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import Login from "../../components/Login";
import Icon from "../../components/Icon";
import Form from "./Form";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import { logInData } from "../../store/authForm";
const optionsPhone = ["🇳🇬 +234",];

const SignIn = (props) => {
  const formData = useSelector((state) => state.authForm.login);
  const dispatch = useDispatch();


  const [phone, setPhone] = useState(optionsPhone[0]);

  const formHandler = useCallback((value) => {
    dispatch(logInData(value));
  }, [dispatch]);

  return (
    <Login
      content="Don’t have an account?"
      linkText="Sign up for free"
      linkUrl="/sign-up"
    >
      <div className={styles.login}>
        <div className={styles.top}>
          <h3 className={cn("h3", styles.title)}>Securely Sign in</h3>
          <div className={styles.info}>
            Securely sign in to your RisenSail.
          </div>

          <Link
            className={styles.correct}
            to="/sign-up"
          >
            <>
              <Icon name="lock" size="24" />
             <span> Do not have an account </span> <div className={styles.url}> Sign-up</div>

            </>
          </Link>

        </div>
        <Form formHandler={formHandler} formData={formData} phone={phone} setPhone={setPhone} optionsPhone={optionsPhone} history={props.history} />
      </div>
    </Login >
  );
};

export default SignIn;
