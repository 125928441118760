import React, { useState, useCallback } from "react";
import cn from "classnames";
import styles from "./TextInput.module.sass";
import Icon from "../Icon";

const TextInput = ({
  className,
  classLabel,
  classInput,
  label,
  name,
  empty,
  view,
  icon,
  note,
  changeHandler,
  ...props
}) => {

  const [propsUpdate, setPropsUpdate] = useState({ type:props.type })
  const handleProbs = useCallback(() => {
    setPropsUpdate({ ...propsUpdate, type: propsUpdate.type === "password" ? "text" : "password" })
  }, [ propsUpdate])

  return (
    <div
      className={cn(
        styles.field,
        { [styles.empty]: empty },
        { [styles.view]: view },
        { [styles.icon]: icon },
        className
      )}
    >
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div className={styles.wrap}>
        <input onChange={(e) => changeHandler({ name, value: e.target.value })} className={cn(classInput, styles.input)} {...props} type={propsUpdate.type} />
        {view && (
          <button onClick={() => handleProbs()} className={styles.toggle}>
            <Icon name="eye" size="24" />
          </button>
        )}
        {icon && (
          <div className={styles.preview}>
            <Icon name={icon} size="24" />
          </div>
        )}
        {note && <div className={styles.note}>{note}</div>}
      </div>
    </div>
  );
};

export default TextInput;
