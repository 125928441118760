import React, { useCallback, useState } from "react";
import cn from "classnames";
import styles from "./SetPin.module.sass";
import { setPin } from "../../../api/pin";
import TextInput from "../../../components/TextInput";
import Message from "../../../components/Message";
import Loader from "../../../components/Loader";
import { profile } from "../../../api/profile";
import { useDispatch } from 'react-redux';
import { updateProfile } from "../../../store/auth";

const FormPhone = () => {
  const [visibleLoader, setVisibleLoader] = useState(false);
  const [pin, setPinValue] = useState("");
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const dispatch = useDispatch();

  const setPinSumit = useCallback(async () => {
    setVisibleLoader(true);
    const setPinRes = await setPin({ pin });

    if (setPinRes) {
      if (setPinRes.status === true) {
        setMessage({ display: true, error: false, message: setPinRes.message });
        const getUserRes = await profile({});
        if (getUserRes) {
          if (getUserRes.status === true) {
            dispatch(updateProfile(getUserRes.data));
          }
        }
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);

      } else {
        setMessage({ display: true, error: true, message: setPinRes.message });
      }
    }

  }, [pin, dispatch])
  return (
    <div className={styles.form}>
      <div className={styles.top}>
        <h3 className={cn("h3", styles.title)}>Set transaction pin</h3>
        <div className={styles.info}>This will be required to approve any transaction on your account</div>
      </div>
      <Message display={message.display} error={message.error} message={message.message} />
      <div className={styles.container}>

        <TextInput
          className={styles.field}
          label="Transaction Pin"
          name="pin"
          type="password"
          value={pin}
          changeHandler={(e) => {
            setPinValue(e.value)
          }}
          placeholder="Pin"
          required
          view
        />
      </div>
      <button className={cn("button", styles.button)} onClick={() => setPinSumit()}>
        {visibleLoader ? <Loader color="white" /> : "Set Pin"}
      </button>

    </div>
  );
};

export default FormPhone;