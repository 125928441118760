import React, { useState, useEffect, useCallback } from "react";
import cn from "classnames";
import styles from "./ProfileInfo.module.sass";
import Profile from "../../components/Profile";
import Modal from "../../components/Modal";
import PhoneEmailVerification from "../../components/PhoneEmailVerification"
import Switch from "../../components/Switch";
import { useSelector } from 'react-redux';
import { enable2fa, addProfileImage, profile } from "../../api/profile";
import cogoToast from 'cogo-toast';
import { useDispatch } from 'react-redux';
import { anable2fa, updateAvatar } from "../../store/auth";
import { changeverificationData } from "../../store/authForm";
import TextInput from "../../components/TextInput";
import { checkTag, createTag } from "../../api/user";
import Message from "../../components/Message";
const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Profile info",
  },
];

const ProfileInfo = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [tag, setTag] = useState("");
  const [copy, setCopy] = useState("copy referral link");
  const [user, setUser] = useState();
  const authForm = useSelector((state) => state.authForm);
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const dispatch = useDispatch()
  const handleChange = (id) => {
    switch (id) {
      case "2fa":
        // enable 2fa on account 
        enable2fa().then(enable2faRes => {
          if (enable2faRes.status === true) {
            cogoToast.success(enable2faRes.message).then(() => dispatch(anable2fa()))
          }
        });

        break;

      default:
        break;
    }
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));

    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }

  };
  const settings = [
    {
      title: "level 1",
      items: [
        {
          title: "Tag",
          content: `${user?.tag}`,
        },
        {
          title: "Withdraw limit",
          content: `₦${parseInt(user?.limit).toLocaleString("en-US")}/day`,
        },
      ],
    }
  ];
  useEffect(() => {
    async function loader() {
      const getProfile = await profile();

      if (getProfile.status) {
        if (getProfile.data?.profile?.twofa === true) {
          if (!selectedFilters.includes('2fa')) {
            setSelectedFilters([...selectedFilters, '2fa'])

          }
        }
        setUser(getProfile.data?.profile);

      }

    }
    loader();

  }, [selectedFilters])

  const handleProfleImage = useCallback(() => {

    const file = document.getElementById('fileUpload').files[0];

    const reader = new FileReader();
    reader.onloadend = async () => {
      const addProfileImageRes = await addProfileImage({ image: reader.result });

      if (addProfileImageRes.state) {
        dispatch(updateAvatar(addProfileImageRes.data?.avatar)).then(() => window.location.reload())
      }
    };
    reader.readAsDataURL(file);


  }, [dispatch])
  const formHandler = useCallback(async (value) => {
    if (value.name === "tag") {
      setTag(value.value);
      if (value.value.length > 3) {
        const checkTagRes = await checkTag({ tag: value.value });

        if (checkTagRes.status) {
          setMessage({ display: true, error: true, message: "Tag already in use" });
        } else {
          setMessage({ display: true, error: false, message: "Tag is available" });
        }
      }
    }
  }, [setTag]);
  if (!user) {
    return <></>
  }
  return (
    <>
      <Profile title="Profile info" breadcrumbs={breadcrumbs}>
        <div className={styles.head}>
          <div className={styles.details}>
            <div className={styles.btns}>
              <div  >
                <img className={styles.profile} onClick={() => document.getElementById("fileUpload").click()} src={user.avatar ? user.avatar[0] : "/images/content/avatar-user.jpg"} alt="Avatar" />
                <div className={styles.pImage}>
                  <input className={styles.fileUpload} id="fileUpload" onChange={() => handleProfleImage()} type="file" />
                </div>
              </div>
            </div>
            <div>
              <div className={styles.user}>{`${user.lastName} ${user.firstName}`}</div>
              <div className={styles.email}>{user.email}{user.email_verified_at ? <img
                width={20}
                srcSet="/images/content/check-mark.png"
                src="/images/content/check-mark.png"
                alt="check"
              /> : ""}</div>
              <div className={styles.phone}>{user.phone}{user.phone_verified_at ? <img
                width={20}
                srcSet="/images/content/check-mark.png"
                src="/images/content/check-mark.png"
                alt="check"
              /> : ""}</div>
              {user.tag ?
                <button className={styles.level} onClick={() => { navigator.clipboard.writeText(`https://risensail.com/sign-up?tag=${user.tag}`); setCopy("copied referral link")}}>{copy}</button>
                : ""}
            </div>
          </div>
        </div>
        {!user.tag ?
          <div className={styles.wrap}>
            <div className={styles.title}>Add Tag</div>

            <Message display={message.display} error={message.error} message={message.message} />

            <div className={styles.row}>

              <div className={styles.col}>
                <TextInput
                  className={styles.field}
                  classLabel={styles.label}
                  classInput={styles.input}
                  label="Tag"
                  name="tag"
                  type="text"
                  changeHandler={formHandler}
                  value={tag}
                  required
                />
              </div>
              <button
                className={cn("button-stroke button-small", styles.button2)}
                onClick={async () => {
                  const createTagRes = await createTag({ tag })
                  if (createTagRes.status) {
                    setMessage({ display: true, error: false, message: createTagRes.message });
                  } else {
                    setMessage({ display: true, error: true, message: createTagRes.message });
                  }
                }}
              >
                Add tag
              </button>
            </div>
          </div>
          : ""}

        <div className={styles.settings}>
          <div className={styles.title}>Features</div>
          {settings.map((x, index) => (
            <div className={styles.box} key={index}>
              <div className={styles.subtitle}>{x.title}</div>
              {x.items.map((item, index) => (
                <div className={styles.item} key={index}>
                  <div className={styles.category}>{item.title}</div>
                  {item.id && (
                    <Switch
                      className={styles.switch}
                      value={selectedFilters.includes(item.id)}
                      onChange={() => handleChange(item.id)}
                    />
                  )}
                  {item.content && (
                    <div className={styles.content}>{item.content}</div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Profile>
      <Modal
        visible={authForm.verification.status}
        onClose={() => dispatch(changeverificationData({ name: "close", value: null }))}
      >
        <PhoneEmailVerification dispatch={dispatch} changeverificationData={changeverificationData} authForm={authForm.verification} />
      </Modal>
    </>
  );
};

export default ProfileInfo;
