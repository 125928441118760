import React from "react";
import Login from "../../components/Login";
import SetPin from "./SetPin";
const SetTransactionPin = () => {

  return (
    <Login
      content="Already set your transaction pin"
      linkText="Dashboard"
      linkUrl="/dashboard"
    >
      <SetPin />
    </Login>
  );
};

export default SetTransactionPin;
