import React from "react";
import styles from "./WalletOverviewDetails.module.sass";
import Wallet from "../../components/Wallet";
import Integrations from "./Integrations";


const Investments = () => {
 
  return (
    <>
      <Wallet>
        
        <div className={styles.head}>Investments</div>
        
        <Integrations />
      </Wallet>
    </>
  );
};

export default Investments;
