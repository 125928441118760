import React from "react";
import cn from "classnames";
import styles from "./Card.module.sass";

const Card = ({ className, item, handleSelect }) => {

  return (
    <div className={cn(className, styles.card)}>
      <div className={styles.preview}>
        <img src={item.image2x} alt="Card" />
      </div>
      <div className={styles.title}>{item.title}</div>
      <div className={styles.bodyCard }>
        <div className={styles.contentNew}>
          <div className={styles.content}>Amount: </div>
          <div className={styles.content}>₦ {parseInt(item.amount).toLocaleString("en-US")}/unit</div>
        </div>
        <div className={styles.contentNew}>
          <div className={styles.content}>Returns: </div>
          <div className={styles.content}>{30}%</div>
        </div>
      </div>
      <button
        className={cn("button-stroke button-small", styles.button)}
        onClick={() => handleSelect({ visible: true, selected_id: item.uuid })}
      >
        {item.button}
      </button>
    </div>
  );
};

export default Card;
