import React, { useCallback, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import Checkbox from "../../../components/Checkbox";
import TextInput from "../../../components/TextInput";
import Message from "../../../components/Message";
import Loader from "../../../components/Loader";

const Form = ({ goNext, formData, formHandler, referral }) => {
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const [displayLoader, setDisplayLoad] = useState(false);
  const formValidation = useCallback(() => {
    setDisplayLoad(true)
    if (formData.firstName === "") {
      setMessage({ display: true, error: true, message: "First name is required" });
      setDisplayLoad(false)
      return false
    }
    else if (formData.lastName === "") {
      setMessage({ display: true, error: true, message: "Last name is required" });
      setDisplayLoad(false)
      return false
    }
    else if (formData.email === "") {
      setMessage({ display: true, error: true, message: "Email is required" });
      setDisplayLoad(false)
      return false
    }
    else if (formData.password === "") {
      setMessage({ display: true, error: true, message: "Password is required" });
      setDisplayLoad(false)
      return false
    }
    else if (formData.password !== formData.confirmPassword) {
      setMessage({ display: true, error: true, message: "Password do not match" });
      setDisplayLoad(false)
      return false
    }
    else if (formData.policy === false) {
      setMessage({ display: true, error: true, message: "Please agree to our policy" });
      setDisplayLoad(false)
      return false
    }
    else {
      setDisplayLoad(false)
      goNext(1)
    }
    setDisplayLoad(false)
  }, [formData, goNext]);

  return (
    <div className={styles.form}>
      <div className={styles.top}>
        <h3 className={cn("h3", styles.title)}>Sign up</h3>
        <div className={styles.info}>Please complete the form below</div>
        {referral?<div className={styles.info}>Referred by {referral}</div>:""}
      </div>
      <div className={styles.container}>
        <Message display={message.display} error={message.error} message={message.message} />

        <TextInput
          className={styles.field}
          label="First Name"
          name="firstName"
          type="text"
          value={formData.firstName}
          changeHandler={formHandler}
          placeholder="John"
          required
        />
        <TextInput
          className={styles.field}
          label="Last Name"
          name="lastName"
          type="text"
          value={formData.lastName}
          changeHandler={formHandler}
          placeholder="Doe"
          required
        />
        <TextInput
          className={styles.field}
          label="email"
          name="email"
          type="email"
          value={formData.email}
          changeHandler={formHandler}
          placeholder="Email address"
          required
        />

        <TextInput
          className={styles.field}
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          changeHandler={formHandler}
          placeholder="Password"
          required
          view
        />
        <TextInput
          className={styles.field}
          label="confirm password"
          name="confirm-password"
          type="password"
          value={formData.confirmPassword}
          changeHandler={formHandler}
          placeholder="Re-enter Password"
          required
          view
        />
        <Checkbox
          className={styles.checkbox}
          value={formData.policy}
          onChange={() => {
            formHandler({ name: "policy" });
          }}
          content="<span>By continuing you agree that I’m above 18 years of age, and to the <a href='https://risensail.com/terms-of-use' target='_blank' rel='noopener noreferrer'>User Agreements</a>, <a href='/https://risensail.com/terms-of-use' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>, <a href='/https://risensail.com/terms-of-use' target='_blank' rel='noopener noreferrer'>Cookie Policy</a>, <a href='/https://risensail.com/terms-of-use' target='_blank' rel='noopener noreferrer'>E-Sign Consent</a>.<span>"
        />
      </div>
      <button className={cn("button", styles.button)} onClick={() => formValidation()}>
        {displayLoader ? <Loader color="white" /> : "Sign up"}
      </button>
    </div>
  );
};

export default Form;
