import React, { useCallback, useState } from "react";
import cn from "classnames";
import styles from "./TwoFaEnabled.module.sass";
import TextInput from "../../../components/TextInput";
import { setPin } from "../../../api/pin";
import cogoToast from 'cogo-toast';

const TwoFaEnabled = ({ isPin }) => {
  const [pin, setformPin] = useState("");
  const changeHandler = useCallback(({ value }) => {
    setformPin(value)
  },[setformPin])
  return (
    <div>
      <div className={cn("h3", styles.title)}>
        Authorization <span>Pin</span>
      </div>
      <div className={styles.text}>
        An authorization pin anable you to carry out any form of transaction on the platform
        Please do not share your authorization pin with anyone.
      </div>

      {!isPin ? <>

        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="Authorization Pin"
            name="pin"
            type="password"
            value={pin}
            placeholder="Pin"
            changeHandler={changeHandler}
            required
            note="This is a four (4) digit pin"
            view
          />
        </div>
        <button className={cn("button-red", styles.button)} onClick={async ()=>{
        const setPinRes =  await setPin({pin});
        if (setPinRes) {
          if (setPinRes.status === true) {
            cogoToast.success(setPinRes.message);
          }
        }
        }} >
          Set Pin
        </button>
      </> : <div className={styles.info}>
        Your authorization pin has been set
      </div>}

    </div>
  );
};

export default TwoFaEnabled;
