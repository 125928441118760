import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Referrals.module.sass";
import Profile from "../../components/Profile";
import { referrals } from "../../api/user";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Referrals",
  },
];

const Referrals = () => {
  const [referral, setReferral] = useState();
  useEffect(() => {
    async function losder() {
      const referralsRes = await referrals();
      console.log(referralsRes);
      if (referralsRes.status) {
        setReferral(referralsRes.data)
      }
    }
    losder();
  }, []);

  if (!referral) {
    return <></>;
  }

  return (
    <Profile title="Referrals" breadcrumbs={breadcrumbs}>
      <div className={styles.stage}>Potential Payout</div>
      <div className={cn("h3", styles.price)}>
        ₦ {parseInt(referral.potentialPayout).toLocaleString("en-US")}
      </div>
      <div className={styles.info}>
        You're earning ₦100 for every one you refer.
      </div>
      <div className={styles.wrap}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Name</div>
            <div className={styles.col}> Status </div>
            <div className={styles.col}> Data/Time</div>
          </div>
          {referral?.referral.length > 0 ? referral?.referral.map(({ id, status, user, created_at }) => (

            <div
              className={styles.row}
              key={id}
            >
              <div className={styles.col}>
                <div className={styles.text}> {`${user[0].firstName} ${user[0].lastName}`}</div>
              </div>

              <div className={styles.col}>
                <div className={cn(status === "pending" ? "category-yellow" : "category-green", styles.text)}>{status}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.text}>{created_at}</div>
              </div>
            </div>
          )) : <>
            <div style={{
              marginLeft: "10%"
            }}>You have no Transaction </div>
          </>}
        </div>
      </div>
      <Link className={cn("button-stroke", styles.button)} to="/dashboard">
        My wallet
      </Link>
    </Profile>
  );
};

export default Referrals;
