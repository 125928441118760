import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    wallet:{}
}

export const walletDetailsSlice = createSlice({
    name: 'walletsDetails',
    initialState,
    reducers: {
        addWalletDetails: (state, action) => {
            state.wallet = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { addWalletDetails } = walletDetailsSlice.actions

export default walletDetailsSlice.reducer