import React, { useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import TextInput from "../../../components/TextInput";
import { changePassword } from "../../../api/profile";
import Message from "../../../components/Message";

const Form = ({ goFinish, changeHandler, formData }) => {
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  return (
    <div className={styles.form}>
      <div className={cn("h3", styles.title)}>New password</div>
      <Message display={message.display} error={message.error} message={message.message} />

      <TextInput
        className={styles.field}
        label="Old password"
        name="oldPassword"
        type="password"
        placeholder="Old Password"
        required
        value={formData.oldPassword}
        changeHandler={changeHandler}
        view
      />
      <TextInput
        className={styles.field}
        label="New password"
        name="password"
        type="password"
        value={formData.password}
        changeHandler={changeHandler}
        placeholder="New Password"
        required
        view
      />
      <button className={cn("button", styles.button)} onClick={async () => {
        if (!formData.oldPassword || formData.oldPassword === "") {
          setMessage({ display: true, error: true, message: "Old password is required" });
        } else if (!formData.password || formData.password === "") {
          setMessage({ display: true, error: true, message: "New Password is required" });
        } else {
          const changePassRes = await changePassword(formData);
          if (changePassRes) {
            if (changePassRes.status === true) {
              setMessage({ display: true, error: false, message: changePassRes.message });
            } else {
              setMessage({ display: true, error: true, message: changePassRes.message });
            }
          }
        }

      }}>
        Change password
      </button>
    </div>
  );
};

export default Form;
