import React, { useEffect, useState, useCallback } from "react";
import cn from "classnames";
import styles from "./Transfer.module.sass";
import Icon from "../Icon";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import { myBanks } from "../../api/bank";
import { listWallet, withdrawal } from "../../api/wallet";
import Loader from "../Loader";
import Message from "../Message";

const Withdraw = () => {
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const [displayLoader, setDisplayLoad] = useState(false);
  const [seletedBank, setSelectedBank] = useState("");
  const [sortedBanks, setSortedBanks] = useState([]);
  const [formData, setFormData] = useState({ bank: "", amount: "", pin: "" });
  const [ballance, setBallance] = useState(0);

  useEffect(() => {
    async function loader() {
      const myBanksRes = await myBanks();
      const listWalletRes = await listWallet();
      if (myBanksRes.status) {
        if (myBanksRes?.data?.length > 0) {
          const theBanks = [];
          for (let index = 0; index < myBanksRes.data.length; index++) {
            theBanks.push(`${myBanksRes.data[index].bank}|${myBanksRes.data[index].account_number}`);
          }
          setSortedBanks(theBanks);
          setSelectedBank(theBanks[0]);
        }
      }

      if (listWalletRes) {
        if (listWalletRes.status === true) {
          setBallance(listWalletRes?.data?.wallets[0].amount)
        }
      }
    }
    loader();
  }, [setSortedBanks, setSelectedBank, setBallance]);

  const formHandler = useCallback((input) => {
    if (!input.name) {
      setSelectedBank(input)
      setFormData({ ...formData, bank: input });
    } else if (input.name === "amount") {
      setFormData({ ...formData, amount: input.value });
    } else if (input.name === "pin") {
      setFormData({ ...formData, pin: input.value });
    }
  }, [formData]);

  const handleSubmit = useCallback(async () => {
    setDisplayLoad(true);
    const withdrawalRis = await withdrawal({ amount: formData.amount, bank: formData.bank, pin: formData.pin });
    if (withdrawalRis.status) {
      setMessage({ display: true, error: false, message: withdrawalRis.message });
      setDisplayLoad(false);
      setFormData({ bank: "", amount: "", pin: "" });
      window.location.reload();
    } else {
      setMessage({ display: true, error: true, message: withdrawalRis.message });
      setDisplayLoad(false);
      setFormData({ ...formData, pin: "" });
    }
  }, [formData, setDisplayLoad, setMessage])
  return (
    <div className={styles.transfer}>
      <div className={cn("h4", styles.title)}>
        <Icon name="arrow-left" size="32" />
        Withdraw to bank
      </div>
      <div className={styles.wrap}>
        <div className={styles.category}>
          Available balance
        </div>
        <div className={styles.details}>
          <div className={styles.currency}>₦ {parseInt(ballance ? ballance : 0).toLocaleString("en-US")}</div>
        </div>
      </div>
      <Message display={message.display} error={message.error} message={message.message} />

      <div className={styles.field}>
        <Dropdown
          className={styles.dropdown}
          label="Select Bank"
          value={seletedBank}
          setValue={formHandler}
          options={sortedBanks}
        />
      </div>
      <div className={styles.box}>
        <TextInput
          className={styles.field}
          label="Amount to withdraw"
          name="amount"
          type="number"
          value={formData.amount}
          changeHandler={formHandler}
          required
        />

      </div>
      <div className={styles.box}>
        <TextInput
          className={styles.field}
          label="Transaction Pin"
          name="pin"
          value={formData.pin}
          type="password"
          changeHandler={formHandler}
          note="Please enter your transaction pin to complete this transaction"
          required
          view
        />

      </div>
      <button onClick={() => handleSubmit()} className={cn("button", styles.button)}>{displayLoader ? <Loader color="white" /> : "Withdraw now"}</button>
    </div>
  );
};

export default Withdraw;
