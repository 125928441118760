import React, { useState, useCallback, useEffect } from "react";
import cn from "classnames";
import styles from "./Transfer.module.sass";
import Icon from "../Icon";
import TextInput from "../TextInput";
import { resolveAddress, sendP2P } from "../../api/wallet";
import Message from "../Message";
import Loader from "../Loader";

const P2p = ({ wallet }) => {
  const [formInput, setFormInput] = useState({ amount: "", address: "", pin: "", description: "" });
  const [addressName, setAddressName] = useState("");
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const [displayLoader, setDisplayLoad] = useState(false);


  const handleFormInput = useCallback(async ({ name, value }) => {
    switch (name) {
      case "amount":
        if (!isNaN(value)) {
          setFormInput({ ...formInput, amount: value })
        }
        break;
      case "address":
        setFormInput({ ...formInput, address: value });
        break;
      case "description":
        setFormInput({ ...formInput, description: value });
        break;
      case "pin":
        if (!isNaN(value)) {
          setFormInput({ ...formInput, pin: value })
        }
        break;
      default:
        break;
    }
  }, [formInput])
  const handleSend = useCallback(async () => {
    setDisplayLoad(true);
    const sendP2PRes = await sendP2P({
      tag: formInput.address,
      amount: formInput.amount,
      description: formInput.description,
      pin: formInput.pin
    });
    if (sendP2PRes) {
      if (sendP2PRes.status === true) {
        setMessage({display: true, error: false, message: sendP2PRes.message});
        setDisplayLoad(false);
        setTimeout(function(){ window.location.reload()}, 2000);
      }else {
        setMessage({display: true, error: true, message: sendP2PRes.message});
        setDisplayLoad(false);
      }
    } else{
      setMessage({display: true, error: true, message: "Unable to process transaction"});
        setDisplayLoad(false);
    }

  }, [formInput])

  useEffect(() => {
    async function load() {
      if (formInput.address) {
        if (formInput.address.length > 10) {
          const resolveAddressRes = await resolveAddress({ address: formInput.address});
          if (resolveAddressRes) {
            if (resolveAddressRes.status === true) {
              setAddressName(resolveAddressRes?.data?.name);
              setMessage({display: true, error: false, message: resolveAddressRes.message});
            } else {
              setMessage({display: true, error: true, message: resolveAddressRes.message});
            }
          }
        } else {
          setAddressName("");
        }
      }
    }
    load();

  }, [formInput.address, wallet.title, setAddressName])


  return (
    <div className={styles.transfer}>
      <div className={cn("h4", styles.title)}>
        <Icon name="arrow-left" size="32" />
        P2P
      </div>
      <div className={styles.wrap}>
        <div className={styles.category}>
          Available balance
        </div>
        <div className={styles.details}>
          <div className={styles.currency}> ₦ {parseInt(wallet.amount).toLocaleString('en-US')}</div>
        </div>
      </div>
      <Message display={message.display} error={message.error} message={message.message} />
     
      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="To "
          name="address"
          type="text"
          value={formInput.address}
          placeholder="0123456789"
          changeHandler={handleFormInput}
          required
        />
      </div>
      <div className={styles.wrap}>
        <div className={styles.category}>
          {addressName}
        </div>
      </div>
      <div className={styles.box}>
        <TextInput
          className={styles.field}
          label="Amount to transfer"
          name="amount"
          type="number"
          value={formInput.amount}
          changeHandler={handleFormInput}
          note={`You will be charge ₦ ${0} for this transaction`}
          required
        />
        <button onClick={() => {
          handleFormInput({ name: "amount", value: (wallet.amount - wallet.p2pCharge) })
        }} className={cn("button-stroke button-small", styles.button)}>
          Max amount
        </button>
      </div>
      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="Description"
          name="description"
          type="text"
          value={formInput.description}
          placeholder="description"
          changeHandler={handleFormInput}
        />
      </div>
      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="Transaction Pin "
          name="pin"
          type="password"
          value={formInput.pin}
          placeholder="01234"
          note={`Please enter your pin to aprove transaction`}
          changeHandler={handleFormInput}
          required
          view
        />
      </div>
      <button onClick={handleSend} className={cn("button", styles.button)}>{displayLoader? <Loader color="white" />: "Send Now"}</button>
    </div>
  );
};

export default P2p;
