import React from "react";
import cn from "classnames";
import styles from "./AdminInvestment.module.sass";
const AdminInvestment = ({ allInvestment }) => {
 
  if (!allInvestment) {
    return <></>
  }
console.log(allInvestment);

  return (
    <div className={styles.wrap}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Title/Type</div>
          <div className={styles.col}>Amount/Unit</div>
          <div className={styles.col}>Payout/Unit type</div>
          <div className={styles.col}>Status/Returns </div>
          <div className={styles.col}> Starts/Ends</div>
          <div className={styles.col}> Date/Time</div>
          <div className={styles.col}> Actions</div>
        </div>
        {allInvestment?.investments.length > 0 ? allInvestment?.investments.map(({ uuid, image, title, type, amount, unit, status, returns, startDate, endDate, created_at }) => (

          <div
            className={styles.row}
            key={uuid}
          >
            <div className={styles.col}>
              <div className={styles.currency}>
                <div className={styles.icon}>
                  <img src={image ? image[0] : "/images/content/avatar-user.jpg"} alt="Avatar" />
                </div>
                <div className={styles.details}>
                  <div className={styles.info}> {title}</div>
                  <div className={styles.text}>{type}</div>
                </div>
              </div>
            </div>

            <div className={styles.col}>
              <div className={styles.info}> ₦ {parseInt(amount).toLocaleString("en-US")}</div>
              <div className={styles.text}>{unit}</div>
            </div>
            <div className={styles.col}>
            <div className={styles.info}><span className={status==="live"? styles.statusGreen: status==="pending"? styles.statusYellow: styles.statusRed}> {status}</span> </div>
            <div className={styles.text}>{returns}%</div>
            </div>
            <div className={styles.col}>
              <div className={styles.info}> {startDate}</div>
              <div className={styles.text}>{endDate}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.text}>{created_at}</div>
            </div>
            <div className={styles.col}>
              <div className={styles.colAct}>

                <button
                  className={cn("button-stroke button-small", styles.buttonGreen)}
                >
                  <span>Subscribers</span>
                </button>

                <button
                  className={cn("button-stroke button-small", styles.buttonBlue)}
                >
                  <span>Payout</span>
                </button>
                <button
                  className={cn("button-stroke button-small", styles.button)}
                >
                  <span>Online</span>
                </button>
                <button
                  className={cn("button-stroke button-small", styles.buttonYellow)}
                >
                  <span>Offline</span>
                </button>
              </div>
              <div className={styles.colAct}>
                <button
                  className={cn("button-stroke button-small", styles.buttonRedLight)}
                >
                  <span>Edit</span>
                </button>
                <button
                  className={cn("button-stroke button-small", styles.buttonRed)}
                >
                  <span>Delete</span>
                </button>
              </div>
            </div>
          </div>
        )) : <>
          <div style={{
            marginLeft: "10%"
          }}>No users </div>
        </>}
      </div>
    </div>
  );
};

export default AdminInvestment;
