import React, { useEffect, useState, useCallback } from "react";
import cn from "classnames";
import styles from "./Referrals.module.sass";
import Profile from "../../components/Profile";
import TextInput from "../../components/TextInput";
import Dropdown from "../../components/Dropdown";
import { bankList, addBank, resolveBank, myBanks } from "../../api/bank";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Banks",
  },
];

const Banks = () => {
  const [rawBanks, setRawBanks] = useState([]);
  const [banks, setBanks] = useState([]);
  const [sortedBanks, setsortedBanks] = useState([]);
  const [myBanksList, setMyBanksList] = useState([]);
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const [formData, setFormData] = useState({ bankCode: "", accountNumber: "" });
  const [selectedBank, setSelectedBank] = useState("");
  const [displayLoader, setDisplayLoad] = useState(false);


  useEffect(() => {
    async function loadBanksList() {
      const loadedBanks = await bankList();
      const loadMybanks = await myBanks();
      if (loadedBanks.status) {
        setRawBanks(loadedBanks.data);
        const bankNames = [];
        for (let index = 0; index < loadedBanks.data.length; index++) {
          bankNames.push(loadedBanks.data[index].name);
        }
        setBanks(bankNames.sort());
        setSelectedBank(bankNames[0]);
        setsortedBanks(bankNames.sort());
      }
      if (loadMybanks.status) {
        setMyBanksList(loadMybanks.data);
      }
    }
    loadBanksList();
  }, [setRawBanks, setBanks, setMyBanksList])

  const search = useCallback((value) => {
    let newArray = sortedBanks.filter(function (str) { return str.toLowerCase().includes(value); });
    if (value === "") {
      setBanks(sortedBanks);
    } else {
      setBanks(newArray);
    }

  }, [setBanks, sortedBanks]);

  const handleClick = useCallback(async (input) => {
    if (!input.name) {
      setSelectedBank(input);
      for (let index = 0; index < rawBanks.length; index++) {
        if (rawBanks[index].name === input) {
          setFormData({ ...formData, bankCode: rawBanks[index].code });
        }
      }
    } else {
      setFormData({ ...formData, accountNumber: input.value });
      if (input.value.length === 10) {
        const resolveAccount = await resolveBank({ ...formData, accountNumber: input.value })
        if (resolveAccount.status) {
          setMessage({ display: true, error: false, message: resolveAccount?.data?.account_name });
        } else {
          setMessage({ display: true, error: true, message: resolveAccount?.message });
        }
      }
    }
  }, [formData, rawBanks])

  const handleSubmit = useCallback(async () => {
    setDisplayLoad(true);
    const addBankRes = await addBank(formData);
    if (addBankRes.status) {
      setMessage({ display: true, error: false, message: addBankRes?.message });
      const loadMybanks = await myBanks();
      if (loadMybanks.status) {
        setMyBanksList(loadMybanks.data);
      }
    } else {
      setMessage({ display: true, error: true, message: addBankRes?.message });
    }
    setDisplayLoad(false);
  }, [formData])

  return (
    <Profile title="Banks" breadcrumbs={breadcrumbs}>
      <div className={styles.stage}>My banks</div>
      <div className={styles.wrap}>
        <div className={styles.title}>Add withdrawal bank</div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Dropdown
              className={styles.field}
              classLabel={styles.label}
              classInput={styles.input}
              label="Select Bank"
              name="bank"
              setValue={handleClick}
              value={selectedBank}
              options={banks}
              search={search}
              required
            />
          </div>
          <div className={styles.col}>
            <TextInput
              className={styles.field}
              classLabel={styles.label}
              classInput={styles.input}
              value={formData.accountNumber}
              label="Account Number"
              name="accountNumber"
              type="text"
              changeHandler={handleClick}
              required
            />

          </div>
        </div>
      </div>
      <Message display={message.display} error={message.error} message={message.message} />

      <button className={cn("button-stroke", styles.button)} onClick={() => handleSubmit()}>
        {displayLoader ? <Loader /> : "Add Bank"}
      </button>
      <div className={styles.table}>
        <div className={styles.tableRow}>
          <div className={styles.tableCol}>
            <div className="sorting">#</div>
          </div>
          <div className={styles.tableCol}>
            <div className="sorting"> Name</div>
          </div>
          <div className={styles.tableCol}>
            <div className="sorting">Account</div>
          </div>
          <div className={styles.tableCol}>Bank Name</div>
        </div>
        {myBanksList.map((x, index) => (
          <div className={styles.tableRow} key={index} >
            <div className={styles.tableCol}>
              <div className={styles.line}>
                {index + 1}
              </div>
            </div>
            <div className={styles.tableCol}>{x.name}</div>

            <div className={styles.tableCol}>{x.account_number}</div>
            <div className={styles.tableCol}>{x.bank}</div>

          </div>
        ))}
      </div>
    </Profile>
  );
};

export default Banks;
