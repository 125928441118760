import { configureStore } from '@reduxjs/toolkit'
import auth from './auth';
import authForm from './authForm'
import wallets from './wallets'
import bills from './bills'
import walletsDetails from './walletsDetails'

export const store = configureStore({
  reducer: {
    auth,
    authForm,
    wallets,
    bills,
    walletsDetails
  },
})