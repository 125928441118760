import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";
import Dropdown from "../../../components/Dropdown";

const items = [
  {
    title: "Account",
    items: [
      {
        title: "How to Register on RisenSail?",
        content:
          `You can register for a RisenSail account with your email address and phone number easily with just a few taps click here <a href='/sign-up' target='_blank' rel='noopener noreferrer'>Get Started Now</a>`,
      },
      {
        title: "How to Complete Identity Verification?",
        content:
          'You can access the Identity Verification from [Profile], or click [Verify Account] from the homepage banners. You can check your current verification level on the page, which determines the investment limit of your RisenSail account. To increase your limit, please complete the respective Identity Verification level.',
      },
      {
        title: "Why Can’t I Receive Emails from RisenSail?",
        content:
          'Have you checked the spam folder of your email? If you find that your email service provider is pushing RisenSail emails into your spam folder, you can mark them as “safe” by whitelisting Risaensail email addresses.',
      },
      {
        title: "Why Can’t I Receive Phone token's from RisenSail?",
        content:
          'We advice that you use your WhatsApp phone number, to bypass DND place by your service provider  ',
      },
    ],
  },
  {
    title: "Wallet",
    items: [
      {
        title: "How to Check Balance and Transfer Funds to account?",
        content:
          'You can check all your RisenSail available balances and totals investment balances on the dashboard page.',
      },
      {
        title: "How to add more funds to my available balances?",
        content:
          'You can add more funds to your RisenSail available balance by making a bank transfer to the account number on your dashboard or by clicking add funds and follow the steps.',
      },
      {
        title: "How to withdraw from my RisenSail account?",
        content:
          'You can withdraw from your available balance to you verified bank account, by clicking on add bank on your dashboard.',
      }
    ],
  },
  {
    title: "Investment",
    items: [
      {
        title: "How can I Invest?",
        content:
          'You can invest in any of the investment opportunities listed on the investment page and follow the easy steps.',
      },
      {
        title: "Can i cancle my investment?",
        content:
          'You can cancle your investment by contacting support, terms and conditions apply.',
      },
      // {
      //   title: "Can i resell my investment?",
      //   content:
      //     'You can resell your investment by clicking on list investment and follow the steps.',
      // }
    ],
  }
];

const Faq = () => {
  const options = [];
  items.map((x) => options.push(x.title));

  const [category, setCategory] = useState(options[0]);

  return (
    <div className={cn("section", styles.section)}>
      <div style={{ marginTop: "40px" }} className={cn("container", styles.container)}>
        <div className={styles.top}>
          <h2 className={cn("h2", styles.title)}>Frequently asked questions</h2>
          <div className={styles.nav}>
            {items.map((x, index) => (
              <button
                className={cn(styles.btn, {
                  [styles.active]: x.title === category,
                })}
                onClick={() => setCategory(x.title)}
                key={index}
              >
                {x.title}
              </button>
            ))}
          </div>
          <Dropdown
            className={styles.dropdown}
            value={category}
            setValue={setCategory}
            options={options}
          />
        </div>
        <div className={styles.list}>
          {items
            .find((x) => x.title === category)
            .items.map((x, index) => (
              <Item
                className={styles.item}
                item={x}
                index={index}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
