import React, { useState, useCallback } from "react";
import Login from "../../components/Login";
import Form from "./Form";
import Verification from "./Verification";

const ForgotPassword = () => {
  const [acitveIndex, setAcitveIndex] = useState(0);
  const [formData, setFormData] = useState({ email: "", password: "", token: "" });
  const formHandler = useCallback((value) => {
    switch (value.name) {
      case "email":
        setFormData({ ...formData, email: value.value })
        break;
      case "password":
        setFormData({ ...formData, password: value.value })
        break;
      case "token":
        setFormData({ ...formData, token: value.value })
        break;
      default:
        break;
    }
  }, [setFormData, formData]);
  return (
    <Login
      content="Don’t have an account?"
      linkText="Sign up for free"
      linkUrl="/sign-up"
    >
      {acitveIndex === 0 && <Form goNext={() => setAcitveIndex(1)} formHandler={formHandler} formData={formData} />}
      {acitveIndex === 1 && <Verification formData={formData} formHandler={formHandler} />}
    </Login>
  );
};

export default ForgotPassword;
