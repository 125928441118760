import React, { useState, useCallback } from "react";
import cn from "classnames";
import styles from "./Transfer.module.sass";
import Icon from "../Icon";
import TextInput from "../TextInput";
import { fundAccount } from "../../api/wallet";
import Loader from "../Loader";
import Message from "../Message";

const DepositModel = () => {
  const [payload, setPayload] = useState({ amount: "" });
  const [visibleLoader, setVisibleLoader] = useState(false)
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const changeHandler = useCallback((e) => {
    
    switch (e.name) {
      case "amount":
        if (!isNaN(e.value)) {
          if (e.value.charAt(0) === 0) {
            setPayload({ ...payload, amount: e.value.slice(1) });
          } else {
            setPayload({ ...payload, amount: e.value });
          }
        }
        break;
      default:
        break;
    }

  }, [payload]);

  const handleSumit = useCallback(async () => {
    setVisibleLoader(true);
    const fundRes = await fundAccount({ amount: payload.amount});
    if (fundRes.status) {
      window.location.href = fundRes.data.authorization_url;
    } else{
      setVisibleLoader(false);
      setMessage({ display: true, error: true, message: fundRes.message });
    }
  }, [payload])
  return (
    <div className={styles.transfer}>
      <div className={cn("h4", styles.title)}>
        <Icon name="arrow-left" size="32" />
        Deposit
      </div>
      <Message display={message.display} error={message.error} message={message.message} />
    
      <div className={styles.box}>
        <TextInput
          className={styles.field}
          label="Amount to transfer"
          name="amount"
          type="number"
          changeHandler={(e) => changeHandler(e)}
          value={payload.amount}
          note="minimun deposit amount is ₦1,000.00"
          required
        />
      </div>
      <button onClick={() => handleSumit()} className={cn("button", styles.button)}>{visibleLoader? <Loader color="white" /> : "Deposit Now"}</button>
    </div>
  );
};

export default DepositModel;
