import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = () => {

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h3", styles.title)}>Privacy Policy</h1>
        </div>
      </div>
      <div className={cn("container", styles.text)}>
        <p><br /></p>
        <p><span className={styles.span1}>We at RiseSail Limited (&ldquo;RisenSail&rdquo; or &ldquo;we&rdquo;) respect your privacy and are committed to protecting it through our compliance with this privacy policy (the &ldquo;Policy&rdquo;). This policy describes:</span></p>
        <p><span className={styles.span1}>i. THE TYPES OF INFORMATION WE MAY COLLECT OR THAT YOU MAY PROVIDE WHEN YOU REGISTER WITH OR USE THE RISENSAIL WEBSITE.</span></p>
        <p><span className={styles.span1}>ii. OUR PRACTICES FOR COLLECTING, USING, MAINTAINING, PROTECTING AND DISCLOSING THAT INFORMATION.</span></p>
        <p><span className={styles.span1}>This Policy applies only to information we collect on the Website and in email, text, and other electronic communications sent through or in connection with the Website. This policy DOES NOT apply to information that:</span></p>
        <p><span className={styles.span1}>i. We collect offline or on any other Risensail websites including websites you may access through our website,</span></p>
        <p><span className={styles.span1}>ii. You provide to or is collected by any third party (see Third-Party Information Collection).</span></p>
        <p><span className={styles.span1}>Please read this Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not register with or use the Website. By registering with or using the Website, you agree to this Policy. This Policy may change from time to time (see Changes to This Policy). Your continued use of the Website after we revise this Policy means you accept those changes, so please check this Policy periodically for updates.</span></p>
        <p><strong><span className={styles.span1}>Information We Collect and How We Collect It</span></strong></p>
        <p><span className={styles.span1}>We collect information from and about users of the Website directly from you when you provide information to us and automatically when you use the Website.</span></p>
        <p><strong><span className={styles.span1}>Information You Provide to Us</span></strong></p>
        <p><span className={styles.span1}>When you register with or use the Website, we may ask you to provide information:</span></p>
        <p><span className={styles.span1}>i. By which you may be personally identified, such as your name, address, email, telephone number, bank verification number, birth date, financial information, or any combination of information that could be used to identify you (&ldquo;personal information&rdquo;)</span></p>
        <p><span className={styles.span1}>ii. That is about you but individually does not identify you, such as information you provide by filling in forms on the Website. This includes information provided at the time of registering to use the Website. We may also ask you for information when you report a problem with the Website.</span></p>
        <p><span className={styles.span1}>iii. Records and copies of your correspondence (including email addresses and phone numbers) if you contact us.</span></p>
        <p><span className={styles.span1}>iv. Your responses to surveys or questions that we might ask you to complete details of transactions you carry out through the Website.</span></p>
        <p><span className={styles.span1}>v. Details of transactions you carry out through the Website.</span></p>
        <p><strong><span className={styles.span1}>Automatic Information Collection</span></strong></p>
        <p><span className={styles.span1}>When you register with or use the Website, it may use technology to automatically collect:</span></p>
        <p><strong><span className={styles.span1}>i. Usage Details</span></strong></p>
        <p><span className={styles.span1}>When you access and use the Website, we may automatically collect certain details of your access to and use of the Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on or through the Website.</span></p>
        <p><strong><span className={styles.span1}>ii. Device Information</span></strong></p>
        <p><span className={styles.span1}>We may collect information about your device and an internet connection, including the device&rsquo;s unique identity, IP address, operating system, browser type, mobile network information, and the device&rsquo;s telephone number.</span></p>
        <p><strong><span className={styles.span1}>iii. Stored Information and Files</span></strong></p>
        <p><span className={styles.span1}>The Website may also access metadata and other information associated with other files stored on your device. This may include, for example, device logs or device usage metadata information.</span></p>
        <p><strong><span className={styles.span1}>iv. Location Information</span></strong></p>
        <p><span className={styles.span1}>The Website collects information about the location of your device and its IP address while the Website is in use. The Website will collect and store a user&rsquo;s location info through several different analytics tools. Risensail may require and collect non-identifying information/data, including but not limited to location, device type, and audio/video information/data from our users. We may disclose this information/data to third parties for the sole purpose of user verification and adherence to applicable local laws and regulations. Generally, the location information we collect is to provide more relevant data to the user and to enhance our understanding of our user base.</span></p>
        <p><strong><span className={styles.span1}>Information Collection Technologies</span></strong></p>
        <p><span className={styles.span1}>The technologies we use for automatic information collection may include:</span></p>
        <p><strong><span className={styles.span1}>i. Cookies&nbsp;</span></strong></p>
        <p><span className={styles.span1}>A cookie is a small file placed on your device. It may be possible to refuse to accept cookies by activating the appropriate setting on your device. However, if you select this setting you may be unable to access certain parts of the Website.</span></p>
        <p><strong><span className={styles.span1}>ii. Web Beacons</span></strong></p>
        <p><span className={styles.span1}>Pages of the Website and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that may permit Risensail, for example, to count users who have visited those pages or opened an email and for other related Website statistics, as well as recording the popularity of certain Website content and verifying system and server integrity.</span></p>
        <p><strong><span className={styles.span1}>Third-Party Information Collection</span></strong></p>
        <p><span className={styles.span1}>When you use the Website or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:</span></p>
        <p><span className={styles.span1}>i. Platform providers.</span><span className={styles.span1}><br /></span><span className={styles.span1}>ii. Analytics companies</span><span className={styles.span1}><br /></span><span className={styles.span1}>These third parties may use tracking technologies to collect information about you when you use the Website, including tools like Google Analytics, Firebase, LiveChat, Facebook SDK, etc. The information they collect may include analytics information, device information, and location information, as well as your email address.</span></p>
        <p><span className={styles.span1}>We do not control these third parties&rsquo; tracking technologies or how they may be used. If you have any questions, you should contact the responsible provider directly.</span></p>
        <p><strong><span className={styles.span1}>How We Use Your Information</span></strong></p>
        <p><span className={styles.span1}>We use information that we collect about you or that you provide to us, including any personal information, to:</span></p>
        <p><span className={styles.span1}>i. Provide you with the Website and its contents and any other information that you request from us.</span></p>
        <p><span className={styles.span1}>ii. Facilitate your activities concerning the Website</span></p>
        <p><span className={styles.span1}>iii. Contact you regarding administrative issues, such as questions about a specific request or otherwise respond to your comments or requests.</span></p>
        <p><span className={styles.span1}>iv. Prevent, detect, mitigate, and investigate fraud, security breaches, and potentially prohibited or illegal activities.</span></p>
        <p><span className={styles.span1}>v. Help us operate the Website.</span></p>
        <p><span className={styles.span1}>vi. Deliver notices regarding your account.</span></p>
        <p><span className={styles.span1}>vii. Comply with IRS and local tax requirements and for tax reporting purposes.</span></p>
        <p><span className={styles.span1}>viii. Provide you with information, such as notices of investment opportunities via the Website, or updates concerning investments that you have participated in.</span></p>
        <p><span className={styles.span1}>ix. Send you information we believe is relevant to you, such as news, bulletins, marketing materials, or other information.</span></p>
        <p><span className={styles.span1}>x. Enforce this Policy and the Terms</span><a href="https://risensail.com/terms-of-use"><u><span className={styles.span3}>&nbsp;and Conditions.</span></u></a></p>
        <p><span className={styles.span1}>xi. Conduct user surveys and questionnaires</span></p>
        <p><span className={styles.span1}>xiii. Comply with applicable law.</span></p>
        <p><span className={styles.span1}>We use aggregated information to analyze the effectiveness of the Website, to improve the Website, and for other similar purposes. In addition, from time to time, we may undertake or commission statistical and other summary analyses of the general behavior and characteristics of users participating in the Website, and may share aggregated information with third parties. We may collect aggregated information through the features of the software that supports the Website, through cookies, and through other means described in this Policy.</span></p>
        <p><span className={styles.span1}>To enable us to better understand the characteristics of our users and/or to provide services tailored to your needs, we may combine your personal information with certain automatically collected information that would not otherwise be personally identifiable. If we combine or link any automatically collected, non-individually identifiable information with your personal information, the resulting combination will be treated as personal information under this Policy.</span></p>
        <p><strong><span className={styles.span2}>Disclosure of Your Information</span></strong></p>
        <p><span className={styles.span1}>We may disclose personal information that we collect or that you provide to our subsidiaries, which may use the personal information in a manner consistent with this Policy. We may disclose aggregated information about our users or information that does not identify any individuals or device, without restriction:</span></p>
        <p><span className={styles.span1}>i. To our subsidiaries and affiliates, which may use the aggregated information we share in a manner consistent with this Policy.</span></p>
        <p><span className={styles.span1}>ii. To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep such aggregated information confidential and use it only for the purposes for which we disclose it to them.</span></p>
        <p><span className={styles.span1}>iii. In the event of a transaction or proposed transaction involving the transfer of substantially all of the assets of Risensail or one or more of its businesses to another entity, whether an affiliate or a third party, or in connection with a bankruptcy, we may share aggregated information in the diligence process or to otherwise facilitate the transaction, and with individuals assisting in the transaction or connection with a bankruptcy. Any aggregated information may also be one of the transferred assets as part of the transaction or bankruptcy.</span></p>
        <p><span className={styles.span1}>iv. To the extent we reasonably believe we are required to do so by law or to comply with any legal or regulatory requirement or request, or to protect against fraud, or illegal activity, to otherwise protect Risensail from liability.</span></p>
        <p><span className={styles.span1}>v. If we believe it is necessary or appropriate to protect the rights, property, or safety of the Website users, any third party, or Risensail.</span></p>
        <p><strong><span className={styles.span1}>Your Choices About Our Collection, Use, and Disclosure of Your Information</span></strong></p>
        <p><span className={styles.span1}>We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of your information.</span></p>
        <p><strong><span className={styles.span1}>i. Tracking Technologies</span></strong></p>
        <p><span className={styles.span1}>You can set your device to refuse all or some cookies or to alert you when cookies are being sent by logging into the Website and visiting your account profile page. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the Website may then be inaccessible or not function properly.</span></p>
        <p><strong><span className={styles.span1}>ii. Location Information</span></strong></p>
        <p><span className={styles.span1}>You can choose whether or not to allow the Website to collect and use information about your device&rsquo;s location. If you block the use of location information, some parts of the Website may then be inaccessible or not function properly.</span></p>
        <p><span className={styles.span1}>In general, you may browse the Website without providing personal information. However, if you choose not to provide certain personal information through the Website, we may be unable to provide certain services or process certain of your requests. You will not be able to become an investor or use all of the features that we offer through the Website. Depending on the functionality associated with the Website feature you are using, you may be able to update or delete certain of your personal information on the Website. Accordingly, at your request, we will take reasonable steps to remove your personal information from our databases. However, please note that:</span></p>
        <p><span className={styles.span1}>i. We may not be permitted to delete your personal information completely, due to IRS and regulatory reporting requirements, investments on the Website and the rights thereof, and data backups and records of deletions.</span></p>
        <p><span className={styles.span1}>ii. If you request deletion of your personal information, you may be unable to use certain features of the Website.</span></p>
        <p><span className={styles.span1}>iii. Certain of your personal information may remain in our databases following the deletion of your account.</span></p>
        <p><span className={styles.span1}>iv. You may not remove de-identified, anonymous, or aggregate data from our databases.</span></p>
        <p><span className={styles.span1}>v. Any deletion requests may be sent to contact@risensail.com</span></p>
        <p><span className={styles.span1}>If you no longer wish to receive marketing emails from us, you can opt out of these communications by logging into the Website and visiting your account profile page or by contacting us at contact@risensail.com. &nbsp;Please understand that you will not be allowed to opt out of transactional notices or other legal and related notices concerning your relationship to the Website or your account.</span></p>
        <p><strong><span className={styles.span1}>Accessing and Correcting Your Personal Information</span></strong></p>
        <p><span className={styles.span1}>You can review and change your personal information by logging into the Website and visiting your account profile page. You may also send us an email at contact@risensail.com &nbsp;to request access to, correct, or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</span></p>
        <p><strong><span className={styles.span1}>Links to Other Sites</span></strong></p>
        <p><span className={styles.span1}>This Policy applies only to information collected on the Website or in emails, texts, and other electronic communications sent through or in connection with the Website. The Website may contain links to other sites that are not owned or controlled by Risensail. Risensail is not responsible for the privacy practices of such other sites, and we encourage you to be aware when you leave the Website and to read the privacy statements of every site that collects your information.</span></p>
        <p><strong><span className={styles.span1}>Data Security</span></strong></p>
        <p><span className={styles.span1}>We have put in place reasonable security systems designed to prevent unauthorized access to or disclosure of personal information, and we take reasonable steps to secure and safeguard this information against loss, theft, and unauthorized use, disclosure, or modification. With that said, there is no such thing as 100% security of the networks, servers, and databases we operate or that are operated on our behalf. Before submitting any personal information via the Website, please be aware of these inherent risks and understand that you do so at your own risk. You also play a role in protecting your personal information. Please do not share your username or password with others. If we receive an order or instructions using your information we will consider that you have authorized the transaction.</span></p>
        <p><strong><span className={styles.span1}>Data Retention</span></strong></p>
        <p><span className={styles.span1}>We keep your personal information for as long as reasonably necessary for the purposes described in this Policy, while we have a legitimate business need to do so in connection with your account, or as required by law (e.g. for tax, legal, accounting, or other purposes), whichever is longer.</span></p>
        <p><strong><span className={styles.span1}>Changes to This Policy</span></strong></p>
        <p><span className={styles.span1}>We may update or modify this Policy at any time, which will become effective as of our posting on the Website. In addition to posting on the Website, we will notify you that this Policy has been changed through the Website alert the first time you use the Website after we make the change.</span></p>

      </div>

    </div>
  );
};

export default Main;
