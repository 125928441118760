import React, { useState, useEffect, useCallback } from "react";
import cn from "classnames";
import styles from "./Data.module.sass";
import Icon from "../Icon";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import { buyData, getBils } from "../../api/bills";
import Message from "../Message";
import Loader from "../Loader";
import { useDispatch } from 'react-redux';
import { profile } from "../../api/profile";
import { addWallet } from "../../store/wallets";

const Data = () => {
  const [providers, setProviders] = useState([]);
  const [airtimeData, setAirtimeData] = useState([]);
  const [plan, setPlan] = useState([]);
  const [visibleForm, setVisibleForm] = useState(true);
  const [formData, setFormData] = useState({ provider: providers[0], phone: "", plan: "", amount: "", planDisplay: "", pin: "" });
  const [message, setMessage] = useState({ display: false, error: false, message: "" });
  const [displayLoader, setDisplayLoad] = useState(false);
  const dispatch = useDispatch();
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    async function load() {
      const profileRes = await profile();
      if (profileRes) {
        if (profileRes.status) {
          dispatch(addWallet(profileRes.data?.wallets));
          if (profileRes.data?.wallets.length > 0) {
            for (let index = 0; index < profileRes.data?.wallets.length; index++) {
              if (profileRes.data?.wallets[index].title === "Available") {
                setBalance(profileRes.data?.wallets[index].amount);
              }

            }
          }
        }
      }
      const getBilsRes = await getBils();
      if (getBilsRes) {
        if (getBilsRes.data) {
          if (getBilsRes.data["airtime_data"]) {
            setAirtimeData(getBilsRes.data["airtime_data"]);
            const defaultPlan = [];
            setProviders(Object.keys(getBilsRes.data["airtime_data"]));
            const data = getBilsRes.data["airtime_data"];
            if (data["MTN-NG"].data) {
              for (let index = 1; index < data["MTN-NG"].data.length; index++) {
                defaultPlan.push(data["MTN-NG"].data[index])
              }
            }
            setPlan(defaultPlan);
          }
        }
      }
    }

    load();
  }, [setFormData, dispatch])

  const handleFormInput = useCallback((input) => {
    if (!input.name && !input.code) {
      const newPlan = [];
      setFormData({ ...formData, provider: input });
      if (airtimeData[input].data) {
        for (let index = 1; index < airtimeData[input].data.length; index++) {
          newPlan.push(airtimeData[input].data[index])
        }
      }
      setPlan(newPlan);
    } else if (input.name === "phone") {
      setFormData({ ...formData, phone: input.value });
    } else if (input.code) {
      setFormData({ ...formData, plan: input.code, planDisplay: input.title, amount: input.price });
    } else if (input.name === "pin") {
      setFormData({ ...formData, pin: input.value });
    }
  }, [formData, airtimeData])


  const handleSubmit = useCallback(async () => {
    setDisplayLoad(true);
    const payload = {
      provider: formData.provider,
      phone: formData.phone,
      plan: formData.plan,
      pin: formData.pin
    }
    const buyDataRes = await buyData(payload)
    if (buyDataRes) {
      if (buyDataRes.status === true) {
        setMessage({ display: true, error: false, message: buyDataRes.message });
        setDisplayLoad(false);
        setTimeout(function(){ window.location.reload()}, 2000);
      } else {
        setMessage({ display: true, error: true, message: buyDataRes.message });
        setDisplayLoad(false);
      }
    }
  }, [formData])

  return (
    <div className={styles.transfer}>
      <div onClick={() => setVisibleForm(true)} className={cn("h4", styles.title)}>
        <Icon name="arrow-left" size="32" />
        Buy Data
      </div>
      {providers.length === 0 ?
        <div className={styles.loderDiv}>
          <Loader />
        </div>
        :
        <>
          <div className={styles.wrap}>
            <div className={styles.category}>
              Available balance
            </div>
            <div className={styles.details}>
              <div className={styles.currency}>₦ {parseInt(balance).toLocaleString("en-US")}</div>
            </div>
          </div>
          <Message display={message.display} error={message.error} message={message.message} />

          {visibleForm ?
            <>
              <div className={styles.field}>
                <Dropdown
                  className={styles.dropdown}
                  label="Select Network"
                  value={formData.provider}
                  setValue={handleFormInput}
                  options={providers}
                />
              </div>
              <div className={styles.field}>
                <Dropdown
                  className={styles.dropdown}
                  label="Select Plan"
                  value={formData.planDisplay}
                  setValue={handleFormInput}
                  options={plan}
                />
              </div>
              <div className={styles.box}>
                <TextInput
                  className={styles.field}
                  label="Phone number to credit"
                  name="phone"
                  type="number"
                  value={formData.phone}
                  changeHandler={handleFormInput}
                  required
                />
              </div>

              <button onClick={() => setVisibleForm(false)} className={cn("button", styles.button)}>Buy ₦{parseInt(formData.amount ? formData.amount : 0).toLocaleString("en-US")} data</button>
            </> :
            <>
              <div className={styles.wrap}>
                <div className={styles.category}>
                  provider <br /> Phone  <br /> Plan
                </div>
                <div className={styles.category}>
                  {formData.provider} <br /> {formData.phone}  <br /> {formData.planDisplay}
                </div>
              </div>
              <div className={styles.box}>
                <TextInput
                  className={styles.field}
                  label="Transaction Pin"
                  name="pin"
                  type="password"
                  value={formData.pin}
                  note="Please enter your transaction pin to complete this action"
                  changeHandler={handleFormInput}
                  required
                  view
                />
              </div>
              <button onClick={() => handleSubmit()} className={cn("button", styles.button)}>{displayLoader ? <Loader color="white" /> : "Buy now"}</button>
            </>}

        </>
      }
    </div>
  );
};

export default Data;
